@import "../../style_variables.scss";

.table.appUsage-dashboard-table {
    display: grid;
    max-width: 100% !important;

    tr {
        border-bottom: 1px solid #c7c7d0;
    }
}

.tableContainer {

    overflow-x: auto;
    .table tbody {
        display: block;
        height: fit-content !important;
        overflow: auto;
    }

    .table thead tr,
    tbody tr {
        display: inline-table;
        width: 100%;
    }

    .table thead tr,
    tbody tr {
        display: flex;
        width: 100%;
        background-color: white;
    }

    .tableRow-color {
        width: 5px;
        height: 100%;
        display: table;
        position: absolute;
        top: 0px;
        left: 0px;
    }

    table> :not(:first-child) {
        border-top: 2px solid transparent;
    }

    .tableTextEllipsis td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        align-content: block;
        display: inline-block;
    }

}

.selectedRow {
    background-color: $toggle-btn-inactive !important;
}

.customTable {
    tr {
        padding: 0 !important;
        vertical-align: middle !important;
    }

    th:first-child,
    td:first-child {
        height: 100%;
        vertical-align: middle !important;
    }

    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(11),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(11) {
        background-color: #9ad5c9;
    }

    th:nth-child(9),
    th:nth-child(10),
    th:nth-child(12),
    th:nth-child(13),
    th:nth-child(14),
    th:nth-child(15),
    th:nth-child(16),
    td:nth-child(9),
    td:nth-child(10),
    td:nth-child(12),
    td:nth-child(13),
    td:nth-child(14),
    td:nth-child(15),
    td:nth-child(16) {
        background-color: #c7e8e1;
    }

    th:nth-child(8),
    td:nth-child(8),
    th:nth-child(18),
    td:nth-child(18) {
        background-color: #e7e4e4;
    }

    tbody tr:nth-child(odd) {
        background-color: #f9f9f9;
    }

    tbody tr:nth-child(even) {
        background-color: #ffffff;
    }

    .superHeaderRow {
        th {
            width: 100%;
            text-align: center;
        }
    
        th:nth-child(3),
        th:nth-child(7),
        th:nth-child(8) {
            background-color: #ffffff;
        }
    
        th:nth-child(4),
        th:nth-child(6) {
            background-color: #c7e8e1;
        }
    }
    
}


.customTable-westpac {
    tr {
        padding: 0 !important;
        vertical-align: middle !important;
    }

    th:first-child,
    td:first-child {
        height: 100%;
        vertical-align: middle !important;
    }

    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(6),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(6) {
        background-color: #9ad5c9;
    }

    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(7),
    th:nth-child(8),
    th:nth-child(9),
    th:nth-child(10),
    th:nth-child(11),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(7),
    td:nth-child(8),
    td:nth-child(9),
    td:nth-child(10),
    td:nth-child(11) {
        background-color: #c7e8e1;
    }

    th:nth-child(13),
    td:nth-child(13) {
        background-color: #e7e4e4;
    }

    tbody tr:nth-child(odd) {
        background-color: #f9f9f9;
    }

    tbody tr:nth-child(even) {
        background-color: #ffffff;
    }

    .superHeaderRow {
        th {
            width: 100%;
            text-align: center;
        }

        th:nth-child(3) {
            background-color: #c7e8e1;
        }

        th:nth-child(2),
        th:nth-child(4){
            background-color: #9ad5c9;
        }

        th:nth-child(6) {
            background-color: #ffffff;
        }
    }
}