// @import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@import "style_variables";

/*.nv-x .nv-axis text{
  transform: rotate(-30deg)!important;
  transform: translateY(20px;);
}*/
html,
body {
    font-family: "Lato", sans-serif;
    color: #26273b !important;
}

.anychart-credits {
    display: none !important;
}

/*@media (min-width: 992px) {
  .modal-lg {
    max-width: 1000px !important;
  }
}*/

// .row {
//   margin-left: 0px!important;
//   margin-right: 0px!important;
// }

.opaque {
    cursor: not-allowed !important;
}

button {
    outline: none;
}

.linkGrey {
    color: $grey-enable;
}

.lightgreyTxt {
    color: $grey-focus;
}

.breadcrumb>.active {
    color: $grey-active !important;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Pointer   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
.cursorPointer {
    cursor: pointer;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Fonts   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
.font-weight-400 {
    font-weight: 400;
}

.font600 {
    font-weight: 600 !important;
}

.font500 {
    font-weight: 500;
}

.font700 {
    font-weight: 700 !important;
}

$sizes: 10, 11, 12, 13, 14, 16, 18, 20, 24, 28;

@each $size in $sizes {
    .font#{$size} {
        font-size: #{$size}px !important;
    }
}

.font24 {
    font-size: 24px;
}

.fontSize-13 {
    font-size: 13px;
}

.padding-20 {
    padding-top: 20px !important;
}

.padding-20m {
    padding-top: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.text-white {
    color: $white;
}

.w-75 {
    width: 75%;
}

.w-25 {
    width: 25%;
}

// .font14 {
//   font-size: 14px;
// }
// .font13{
//   font-size: 13px;
// }
// .font28{
//   font-size: 28px;
// }
// .font10{
//   font-size: 10px;
// }

@function position($option) {
    @return $option;
}

.textCapitalize {
    text-transform: capitalize;
}

.textUpperCase {
    text-transform: uppercase;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Margins   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$margin: 0, 2, 3, 4, 5, 7, 8, 10, 11;

@each $margin in $margin {
    .margin#{$margin} {
        margin: #{$margin}px !important;
    }
}

// marginTop
.marginTop1-5em {
    margin-top: 1.5em;
}

$marginTop: -92,
    -64,
    -37,
    -30,
    -26,
    -20,
    -15,
    -10,
    -5,
    -3,
    -2,
    -1,
    0,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    10,
    11,
    12,
    15,
    16,
    17,
    20,
    23,
    25,
    30,
    32,
    35,
    36,
    38,
    40,
    42,
    45,
    47,
    50,
    53,
    55,
    57,
    60,
    63,
    80,
    90,
    100,
    147,
    150;

@each $top in $marginTop {
    .marginTop-#{$top} {
        margin-top: #{$top}px !important;
    }
}

.marginTop5-percent {
    margin-top: 5% !important;
}

.marginTop-m10 {
    margin-top: -10px;
}

.marginTop-m25 {
    margin-top: -25px;
}

// marginRight
$marginRight: -170,
    -26,
    -13,
    -5,
    0,
    3,
    5,
    7,
    10,
    15,
    17,
    20,
    22,
    24,
    25,
    30,
    35,
    45,
    90,
    424,
    800;

@each $right in $marginRight {
    .marginRight-#{$right} {
        margin-right: #{$right}px !important;
    }
}

.margin10 {
    margin-right: 10px;
}

// marginLeft
$marginLeft: -39,
    -30,
    -25,
    -24,
    -27,
    -15,
    -13,
    -11,
    -10,
    -9,
    -5,
    0,
    4,
    5,
    7,
    8,
    10,
    14,
    15,
    16,
    20,
    24,
    25,
    26,
    30,
    35,
    40,
    65,
    66,
    80,
    300,
    400;

@each $left in $marginLeft {
    .marginLeft-#{$left} {
        margin-left: #{$left}px;
    }
}

// marginBottom
$marginBottom: -77,
    -44,
    -35,
    -32,
    -24,
    -5,
    0,
    2,
    5,
    8,
    10,
    15,
    20,
    30,
    35,
    50,
    60,
    65,
    70,
    75;

@each $bottom in $marginBottom {
    .marginBottom-#{$bottom} {
        margin-bottom: #{$bottom}px;
    }
}

$marginLR: 0, 5, 8, 10, 19, 15, 30;

@each $LR in $marginLR {
    .marginLR-#{$LR} {
        margin-left: #{$LR}px !important;
        margin-right: #{$LR}px !important;
    }
}

$marginTB: 0, 2, 5, 8, 10, 12;

@each $TB in $marginTB {
    .marginTB-#{$TB} {
        margin-top: #{$TB}px;
        margin-bottom: #{$TB}px;
    }
}

.marginTopBottom-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.marginBottom-1rem {
    margin-bottom: 1rem !important;
}

.marginBottom-2rem {
    margin-bottom: 2rem !important;
}

.margin-0-auto {
    margin: 0px auto;
}

.marginAuto {
    margin: auto;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Padding   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$padding: 0, 5, 8, 10, 15, 16, 18, 20, 30, 35;

@each $pd in $padding {
    .padding#{$pd} {
        padding: #{$pd}px !important;
    }
}

// paddingTop
$paddingTop: 0, 2, 4, 5, 10, 15, 20, 30, 34, 50;

@each $top in $paddingTop {
    .paddingTop-#{$top} {
        padding-top: #{$top}px !important;
    }
}

$paddingLeft: 0, 2, 3, 5, 10, 15, 16, 20, 25, 34, 35, 50, 65, 76, 80, 90, 584;

@each $left in $paddingLeft {
    .paddingLeft-#{$left} {
        padding-left: #{$left}px !important;
    }
}

$paddingRight: 0, 1, 10, 15, 20, 25, 35;

@each $right in $paddingRight {
    .paddingRight-#{$right} {
        padding-right: #{$right}px !important;
    }
}

$paddingBottom: 0, 6, 8, 10, 18, 20, 25, 35, 50, 60, 70;

@each $bottom in $paddingBottom {
    .paddingBottom-#{$bottom} {
        padding-bottom: #{$bottom}px !important;
    }
}

$paddingLR: 0, 5, 15, 20, 25, 35, 76, 482;

@each $LR in $paddingLR {
    .paddingLR-#{$LR} {
        padding-left: #{$LR}px !important;
        padding-right: #{$LR}px !important;
    }
}

.paddingLeft570 {
    padding-left: 570px;
}

.paddingR-60 {
    padding-right: 60px;
}

.paddingTB-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.paddingTB-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.paddingTB-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.paddingTB-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.paddingT-300 {
    padding-top: 300px;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Positions   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$positions: (
    "Relative": relative,
    "Unset": unset,
    "Absolute": absolute,
    "Center": center,
    "Fixed": fixed,
    "Contents": contents
);

@each $name, $pos in $positions {
    .position#{$name} {
        @if $name=="Center" {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @else {
            position: $pos !important;
        }
    }
}

$topPos: -50,
    -30,
    -15,
    -10,
    -4,
    -2,
    0,
    5,
    6,
    7,
    10,
    15,
    30,
    33,
    40,
    50,
    90,
    120,
    200,
    363;

@each $top in $topPos {
    .top#{$top} {
        top: #{$top}px;
    }
}

$btPos: 0, 110;

@each $bt in $btPos {
    .bottom#{$bt} {
        bottom: #{$bt}px;
    }
}

$rtPos: -186,
    -158,
    -20,
    0,
    10,
    15,
    100,
    120,
    145,
    240,
    229,
    287,
    290,
    294;

@each $rt in $rtPos {
    .right#{$rt} {
        right: #{$rt}px !important;
    }
}

$lftPos: -186,
    -158,
    -20,
    0,
    10,
    15,
    20,
    100,
    120,
    145,
    240,
    229,
    287,
    290,
    294;

@each $lft in $lftPos {
    .left#{$lft} {
        left: #{$lft}px !important;
    }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Displays   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$displays: (
    // "Flex": flex !important,
    // "Block": block !important,
    "Flex": flex,
    "Block": block,
    "None": none,
    "InlineFlex": inline-flex,
    "Initial": initial,
    "Inline": inline,
    "Content": contents,
    "InlineBlock": inline-block,
    "Inherit": inherit,
    "Grid": grid,
    "FlowRoot": flow-root
);

@each $name, $dis in $displays {
    .display#{$name} {
        display: $dis;
    }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Align Center   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
.alignCenter {
    display: flex;
    align-items: center;
}

.justifyCenter {
    justify-content: center;
}

.justifyEnd {
    justify-content: end;
}

.spaceBetween {
    justify-content: space-between;
}

.startAlign {
    text-align: start;
}

.text-alignLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Overflow   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$overflows: (
    "Y-hidden": hidden,
    "Unset": unset,
    "Auto": auto,
    "Inherit": inherit,
    "Hidden": hidden
);

@each $name, $dis in $overflows {
    .overflow#{$name} {
        overflow: $dis !important;
    }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Float   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Transform   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
.transform90 {
    transform: rotate(90deg);
}

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Width @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$widths: (
    "100": 100%,
    "25": 25%,
    "50": 50%,
    "60": 60%,
    "70": 70%,
    "80": 80%,
    "90": 90%,
    "10px": 10px,
    "15": 15px,
    "17": 17px,
    "20": 20px,
    "30px": 30px,
    "50px": 50px,
    "94": 94px,
    "120": 120px,
    "125": 125px,
    "150": 150px,
    "200": 200px,
    "215": 215px,
    "230": 230px,
    "240": 240px,
    "250": 250px,
    "280": 280px,
    "300": 300px,
    "350": 350px,
    "380": 380px,
    "400": 400px,
    "500": 500px,
    "675": 675px,
    "700": 700px,
    "800": 800px,
    "Auto": auto,
    "Inherit": inherit
);

@each $name, $width in $widths {
    .width#{$name} {
        width: $width;
    }
}

.width40 {
    width: 40%;
}

.width30 {
    width: 30%;
}

.width100-px {
    width: 100px;
}

.width-100 {
    width: 100% !important;
}

.width60-precent {
    width: 60% !important;
}

.widthAuto {
    width: auto !important;
}

.modal {
    z-index: 9992;
}

.modal-md {
    max-width: 650px;
}

$maxWidths: (
    "200": 200,
    "250": 250px,
    "650": 650px
);

@each $name, $width in $maxWidths {
    .maxWidth#{$name} {
        max-width: $width;
    }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Height   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
$heights: 0, 10, 15, 20, 24, 25, 35, 37, 40, 45, 47, 50, 60, 65, 74, 78, 100, 200, 300, 340, 350, 400, 410, 450, 480,
    500, 526, 530, 535, 550, 600, inherit;

@each $height in $heights {
    .height#{$height} {
        @if $height==inherit {
            height: inherit !important;
        }

        @else {
            height: #{$height}px !important;
        }
    }
}

.height100-percent {
    height: 100%;
}

.height100-vh {
    height: 100vh;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Tooltip   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
.tooltipText {
    /*width: 100px;*/
    background-color: $grey6;
    padding: 5px 0px;
    border-radius: 4px;
    color: $black;
}

.tooltipBG .tooltip.show {
    background: transparent;
}

.tooltip.show {
    opacity: 1 !important;
    background: transparent;
}

// for app list modal (Deleted App tooltip)
.searchBox .tooltip.show {
    opacity: 1 !important;
    background: transparent;
    top: 16px !important;
}

.bs-tooltip-auto .arrow,
.bs-tooltip-right .arrow {
    left: -3px;
}

.tooltip-inner {
    background-color: $lightgray !important;
    border: 1px solid $section-tooltip-border !important;
    text-align: left !important;
    opacity: 1 !important;
    color: #000 !important;
    min-width: 0px;
    max-width: 350px !important;
    // left: 4px;
    margin: 0px 4px;

    position: relative;
}

.pagesTable .tooltip-inner {
    max-width: 500px;
}

.tableDetailsTooltip {
    z-index: 999;

    .tooltip-inner {
        max-width: 350px !important;
    }
}

.guideSummaryTooltip .tooltip-inner {
    max-width: 350px;
}

.tooltipTable .tooltip-inner {
    max-width: 350px;
}

.orgTitle .tooltip-inner {
    max-width: 350px;
}

.filterSection .tooltip-inner {
    max-width: 500px;
}

.tooltipPadding .tooltip-inner {
    background-color: $lightgray !important;
    border: 1px solid $section-tooltip-border !important;
    text-align: left !important;
    opacity: 1 !important;
    color: $black;
    min-width: 0px;
    padding-top: 15px;
}

.tooltip {
    position: absolute;
    text-align: center;
    height: 25px;
    padding: 3px;
    font-size: 14px !important;
    font-family: "Lato", sans-serif !important;
    // background: #d6d6d6;
    background: transparent;
    border: 0px;
    border-radius: 4px;
    pointer-events: none;
}

/*.tooltip {
  top: 60px !important;

}*/

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.bs-tooltip-bottom .arrow:before {
    border-bottom-color: $section-tooltip-border !important;
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before,
.bs-tooltip-right .arrow:before {
    border-right-color: $section-tooltip-border !important;
}

.tooltipText:hover {
    background-color: $lightgray;
}

.countColor-black {
    color: $black;
    margin-right: 8px;
}

/* tooltip ppt text color*/

.textYellow {
    color: #b57809;
}

#category_main_div .tooltip-inner {
    border: 1px solid $grey6 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    max-width: 400px !important;
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 13px !important;
    background-color: $grey6 !important;
    text-align: left !important;
    opacity: 1 !important;
    color: $black;
    min-width: 0px;
}

#category_main_div .bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
#category_main_div .bs-tooltip-bottom .arrow:before {
    border-bottom-color: $grey6 !important;
}

/* Tooltip */

.info-icon-color:before {
    color: $priority-info;
}

.height535ingText .tooltip-inner {
    color: darkgrey !important;
    background-color: $section-tooltip-bg !important;
    border: 1px solid $section-tooltip-border !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    max-width: 380px !important;
    min-width: 330px !important;
    text-align: justify !important;
}

.orgPriorityProcessing .tooltip-inner {
    max-width: 100%;
}

.fontGrey {
    color: grey !important;
}

.fontGreyCard {
    color: $fontgrey !important;
}

.headingText .bs-tooltip-auto[x-placement^="right"] .arrow:before,
.headingText .bs-tooltip-right .arrow:before {
    border-right-color: $section-tooltip-border !important;
    /* top:30px;*/
}

.headingText .bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.headingText .bs-tooltip-bottom .arrow:before {
    border-bottom-color: $section-tooltip-border !important;
    /* top:30px;*/
}

.headingText .bs-tooltip-auto[x-placement^="left"] .arrow:before,
.headingText .bs-tooltip-left .arrow:before {
    border-left-color: $section-tooltip-border !important;
    /* top:30px;*/
    left: 4px;
}

.headingText .bs-tooltip-bottom {
    background-color: transparent !important;
}

.sentimentList .tooltip-inner {
    color: black !important;
    // background-color: lightgray !important;
    // border: 1px solid lightgray !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    max-width: 380px !important;
    min-width: 380px !important;
    text-align: justify !important;
    margin-top: 3px;
}

.fontGrey {
    color: grey !important;
}

.sentimentList .bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.sentimentList .bs-tooltip-bottom .arrow:before {
    border-bottom-color: $section-tooltip-border !important;
    left: 0px !important;
    /* top:30px;*/
}

.sentimentList .bs-tooltip-bottom {
    background-color: transparent !important;
}

// .dashboardList .tooltip-inner {
//     color: black !important;
//     background-color: lightgray !important;
//     border: 1px solid lightgray !important;
// }

// .dashboardList .bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
// .dashboardList .bs-tooltip-bottom .arrow:before {
//     border-bottom-color: lightgray !important;
//     left: 0px !important;
//     /* top:30px;*/
// }

// .dashboardList .bs-tooltip-bottom {
//     background-color: transparent !important;
// }

/* App Tooltip */

.appList .tooltip-inner {
    color: darkgrey !important;
    background-color: $section-tooltip-bg !important;
    border: 1px solid $section-tooltip-border !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    max-width: 350px !important;
    min-width: 350px !important;
    padding-bottom: 10px;
    padding-top: 10px;
    /* text-align: justify; */
    display: block;
}

.appList .bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.appList .bs-tooltip-bottom .arrow:before {
    border-bottom-color: $section-tooltip-border !important;
}

.appList .tooltip.show {
    opacity: 0.9;
    background-color: transparent;
    top: 83px !important;
    left: -8px !important;
}

.bs-tooltip-bottom .arrow {
    top: -2px;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Other Common   @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
.containerMaxWidth-0 {
    max-width: 100% !important;
}

.refereshDiv {
    position: absolute;
    right: 0px;
    height: 100%;
    /* z-index: 1000; */
}

.refereshDiv i {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.stepDeleted {
    float: right;
    color: $error-red;
    font-size: 12px;
}

.stepDeletedCard {
    float: right;
    color: $error-red;
    font-size: 12px;
}

.verticalMiddle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.truncate__body {
    position: absolute;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 50%;
    transform: translateY(-50%);
}

.dataTitle-color {
    color: $table-font-link !important;
    cursor: pointer;
}

.nv-tooltipText {
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflowOrgName {
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
}

.nvd3.nv-noData {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: black !important;
}

.nv-noData {
    dominant-baseline: auto !important;
}

.noDataText-font {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: $fontgrey !important;
    background-color: $white !important;

    td {
        padding-top: 39px !important;
    }
}

.textCenter {
    text-align: center !important;
}

.textCenter-absolute {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0px;
}

.paginations-showingOf-text marginLeft-5 {
    color: $grey-enable;
}

.paginations-count-text {
    font-weight: 600;
}

#featuresChart svg {
    width: 100% !important;
}

#featuresChart .nv-legendWrap {
    transform: translate(590px, 0) !important;
}

.section-heading-div {
    margin-top: 15px;
    margin-bottom: -14px;
}

/* no data text */

.overflowX-auto {
    overflow-x: auto;
}

$minWidth: 120, 130, 500, 800;

@each $wt in $minWidth {
    .minWidth#{$wt} {
        min-width: #{$wt}px !important;
    }
}

$maxWidth: 120, 130;

@each $wt in $maxWidth {
    .maxWidth#{$wt} {
        max-width: #{$wt}px !important;
    }
}

$minHeight: 35, 40, 80, 90, 100, 102, 130, 150, 200, 250, 260, 270, 290, 300, 320, 325, 335, 307, 350, 400, 401, 422,
    430, 450, 456, 490, 500, 550, 950;

@each $ht in $minHeight {
    .minHeight#{$ht} {
        min-height: #{$ht}px !important;
    }
}

.minHeight35 {
    min-height: 35px;
}

.textCenter-absolute {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0px;
}

.page-insightContainer form .form-group,
.dashboard-container form .form-group,
.org-view-container form .form-group {
    position: fixed !important;
    right: 25px !important;
    z-index: 10;
    top: 80px;
}

@media (max-width: 574px) {

    .page-insightContainer form .form-group,
    .dashboard-container form .form-group,
    .org-view-container form .form-group {
        position: fixed !important;
        right: 10px !important;
        top: 80px;
        z-index: 10;
    }
}

@media (min-width: 800px) {

    .page-insightContainer form .form-group,
    .dashboard-container form .form-group {
        position: fixed !important;
        right: 25px !important;
        top: 80px !important;
        z-index: 10;
    }
}

@media (min-width: 576px) {

    .page-insightContainer form .form-group,
    .dashboard-container form .form-group {
        position: fixed !important;
        right: 25px !important;
        top: 80px !important;
        z-index: 10;
    }
}

.page-insightContainer #_datepicker,
.dashboard-container #_datepicker {
    position: fixed;
    z-index: 1000;
    background-color: $white;
    right: 288px;
    height: 330px;
    top: 115px;
    border-radius: 0px;
}

.retain-user-chart form .form-group {
    position: absolute !important;
    /* right: 10px !important; */
    right: -1px !important;
    top: 40px !important;
    z-index: 8;
}

.z-index1000 {
    z-index: 1000;
}

.z-index998 {
    z-index: 9;
}

.node rect {
    fill: $lightgray;
}

.node text {
    font: 10px sans-serif;
}

.node tspan:last-child {
    /*font-size: 9px;*/
    fill-opacity: 0.8;
}

.node--internal text {
    font-weight: bold;
}

.node--leaf rect {
    fill-opacity: 0.6;
}

/* You can add global styles to this file, and also import other style files */

/*body{
        background-color: #eee;
}*/

.content {
    padding-bottom: 40px;
}

.siteLayout-block {
    /*min-height: 100vh;*/
    display: block;
}

.mainSection {
    min-height: 100%;
    display: block;
}

.tick text {
    font-size: 12px;
}

.axis path,
.axis line {
    fill: block;
    stroke: $fontgrey;
    stroke-width: 1;
}

.x.axis .tick line {
    display: block;
}

.domain {
    stroke: $fontgrey !important;
    stroke-width: 1 !important;
}

.legend,
.legendData {
    font-size: 12px;
    font-family: "Lato", sans-serif;
}

.rect {
    stroke-width: 2;
}

.survey-title span {
    font-size: 12px;
}

.charts-wrapper {
    background: $white;
    border-top: 3px solid $chart-wrapper-blue;
    margin: 10px 0 20px 0;
    overflow: hidden;
    min-height: 400px;
}

.charts-title {
    margin-top: 10px;
}

.round-input {
    border-radius: 4px;

    &:focus {
        border: 1px solid $primaryColor-active;
    }
}

.bg-secondary {
    background: $lightgray !important;
}

.responseComment {
    font-weight: normal;
    font-size: 14px;
    color: $black;
    display: block;
}

.surveyTime-icon {
    margin-right: 5px;
}

.feedback-timestamp {
    color: $lightgray;
    display: block;
    margin-top: 10px;
}

.green-icon {
    color: $lightgreen;
}

.question-detail-panel {
    margin-top: 20px;
}

.summaryQues-card {
    border-radius: 6px;
    // overflow: hidden;
    border: 1px solid $grey-enable;
    // border: none!important;
    // .borderDiv{
    //   border: 1px solid $grey-enable;
    //   padding: 1px;
    //   border-radius: 4px;
    // }
}

.quesExport-btn-div {
    position: absolute;
    width: calc(100% - 40px);
    display: inline-block;
    top: 40px;
}

.quesType {
    font-size: 14px;
    color: $grey-enable;
    font-weight: normal;
    margin-left: 0px;
    white-space: nowrap;
}

.quesNumber-text {
    color: $grey-enable;
    font-size: 18px;
    padding: 3px;
}

.question-detail-panel .panel-heading {
    padding: 30px;
}

.fa.pull-right {
    margin-left: 0.8em;
}

.quesStatement {
    display: inline-flex;
    align-items: center;
}

.question-detail-panel .panel-title a.question-title {
    color: $table-font-link;
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
}

.pagination li a {
    cursor: pointer !important;
}

.pointer {
    cursor: pointer;
}

.nv-axislabel {
    /*font-weight: 600 !important;*/
}

.hand-pointer {
    cursor: pointer;
}

// .pagination {
//   display: inline-block;
//   padding-left: 0;
//   padding-right: 20px;
//   margin: 20px 0;
//   border-radius: 4px;
// }

// #switcher_pagination .pagination {
//   padding-right: 20px !important;
//   margin: 8px 0 !important;
// }

// .pagination > li {
//   display: inline-block;
//   border-radius: 2px;
// }

// .pagination > .active > a,
// .pagination > .active > a:focus,
// .pagination > .active > a:hover,
// .pagination > .active > span,
// .pagination > .active > span:focus,
// .pagination > .active > span:hover {
//   z-index: 0;
// }

// .pagination > .active > a,
// .pagination > .active > a:focus,
// .pagination > .active > a:hover,
// .pagination > .active > span,
// .pagination > .active > span:focus,
// .pagination > .active > span:hover {
//   z-index: 3;
//   cursor: default;
//   /*background-color: #0C8AFF;
//     border-color: #0C8AFF;*/
//   // background-color: #00a3e3;
//   // border-color: #00a3e3;
// }

// .pagination > li > a,
// .pagination > li > span {
//   position: relative;
//   float: left;
//   padding: 6px 12px;
//   margin-left: -1px;
//   line-height: 1.42857143;
//   color: $purple-active;
//   text-decoration: none;
//   background-color: $white;
//   border: 1px solid $lightgray;
// }

// .pagination a {
//   text-decoration: none !important;
// }

/*overridding theme*/

aside.left-panel {
    /*width: 220px;*/
    width: 14%;
    /* background: rgb(36, 45, 50); */
    // background: #26273a;
    padding: 0;
}

.nav>li>a:hover,
.nav>li>a:focus {
    background-color: transparent !important;
}

.navbar .navbar-header {
    /*width: 90%;*/
}

/*.navbar .navbar-nav > li {
    padding: 0 25px;
}*/

.navbar .navbar-nav>li a {
    padding: 14px 10px;
    display: inline-block;
    box-sizing: border-box;
}

.navbar .navbar-nav li.menu-item-has-children .sub-menu a {
    padding: 2px 0 2px 47px;
}

.navbar .navbar-nav>li {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    min-height: 48px;
    box-sizing: content-box;
    /* position: relative; */
}

.logoDivider {
    height: 60px !important;
    top: 0px !important;
    left: 239px !important;
}

header.header-new {
    background: $white;
    // box-shadow: 0 4px 5px #0000001a!important;
    box-shadow: 0px 2px 2px 0px #2122263d !important;
    box-shadow: 0px 0px 2px 0px #0000001f !important;
    clear: both;
    display: block;
    padding: 9px 0px 15px 20px;
    width: 100%;
    height: 74px;
}

header.header-new a .new-logo {
    width: 192px !important;
    height: 50px !important;
    margin-top: 0px !important;
    margin-left: 1px !important;
    background-size: contain;
    background-position: center;
    margin: 5px;
    background-repeat: no-repeat;
    position: absolute;
    left: 130px;
    cursor: default;
}

header.header-new .forgotPassword .new-logo {
    width: 192px !important;
    height: 60px !important;
    margin-top: -9px !important;
    margin-left: 9px !important;
    background-size: contain;
    background-position: center;
    margin: 5px;
    background-repeat: no-repeat;
    top: 5px;
}

.pagesIcons {
    vertical-align: middle;
    border-style: none;
    margin-top: -2px;
}

.user-icon-name {
    line-height: 38px;
    /*float: right;*/
    /* padding: 10px; */
    margin-left: 10px;
    font-size: 13px;
}

.menu-icon-img {
    margin-right: 10px;
    // height: 20px;
    // width: 20px;
    // top: 4px;
    position: relative;
}

.nav.navbar-nav li:nth-child(2) .menu-icon-img svg,
.nav.navbar-nav li:nth-child(3) .menu-icon-img svg,
.nav.navbar-nav li:nth-child(4) .menu-icon-img svg {
    width: 20px;
    height: 20px;
}

.nav.navbar-nav li:nth-child(2) .menu-icon-img,
.nav.navbar-nav li:nth-child(3) .menu-icon-img,
.nav.navbar-nav li:nth-child(4) .menu-icon-img {
    top: 0px;
}

.nav.navbar-nav li:nth-child(2) span,
.nav.navbar-nav li:nth-child(3) span,
.nav.navbar-nav li:nth-child(4) span {
    top: 1px;
    position: relative;
}

.guideMe-svg {
    top: 3px !important;
}

.nav.navbar-nav li:nth-child(5) .menu-icon-img {
    // left: 4px;
}

.nav.navbar-nav li:nth-child(5) .menu-icon-img svg {
    height: 20px;
}

.menu-item-has-children .menu-icon-img {
    top: 2px;
    position: relative;
}

.sub-menu .menu-icon-img {
    top: 3px;
}

.sub-menu .menu-icon-img {
    height: 16px;
    width: 16px;
}

.page-header {
    margin-bottom: 10px;
}

.right-panel .breadcrumbs {
    margin-top: 77px;
}

.stat-widget-one {
    text-align: center;
}

.stat-digit {
    font-size: 16px;
    color: $fontgrey;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    margin-top: 5px;
}

.stat-text {
    font-size: 20px;
    color: $fontgrey;
    font-weight: 600;
    text-align: left;
    /*display: inline-block; */
    /*margin-left: -166px;  */
}

.stat-widget-one .stat-icon {
    margin-top: 6px;
}

.card-heading {
    color: $fontgrey;
    font-weight: 900;
}

.card-subheading {
    color: $fontgrey;
    margin-top: 10px;
}

.page-title-new {
    padding: 0px 0 9px 0;

    h4 {
        font-size: 24px !important;
        margin-top: 10px;
    }
}

h4 {
    margin-top: 0px;
}

.active-menu {
    background: $activeMenu;
}

.active-menu>a {
    // padding: 14px 20px 14px 20px !important;
    // color: $white !important;
    // border-left: 5px solid $primaryColor-active;

    // border-top: 1px solid $activeGreenColor;
    // border-bottom: 1px solid $activeGreenColor;
    &:hover {
        // color: $white !important;
    }

    svg {
        path {
            // fill: $white !important;
        }

        g {
            // fill: $white !important;
        }

        circle {
            // stroke: $white !important;
        }
    }
}

.active-menu>a>i {
    // color: $primaryColor-active;
}

.link-a {
    display: flex !important;
    align-items: center;
}

.sideNav-icons {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px !important;
}

.sideNav-icons svg:not(:root) {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px !important;
}

/* .sideNav-iconBg-color svg:not(:root) path,.sideNav-iconBg-color svg:not(:root) polygon{
 fill: $white;
} */

.active-button {
    background: $primaryColor-active !important;
    color: $white !important;
    border-radius: 49px;
    border-color: $primaryColor-active !important;
}

.navbar .navbar-nav li.menu-item-has-children .sub-menu {
    padding: 0 0 0 0px;
}

.menu-item-has-children.active-menu li {
    background: rgb(57, 63, 67);
}

.menu-item-has-children.active-menu li.active-child-menu {
    background: rgb(47, 52, 55);
}

.menu-item-has-children.active-menu li.active-child-menu a {
    color: rgb(0, 161, 225) !important;
}

.navbar .navbar-nav li.menu-item-has-children a:before {
    right: 10px;
}

.menu-icon-img.active-sub-menu {
    background-color: $activeSubMenu;
    color: $white;

    svg {
        path {
            fill: $white !important;
        }

        g {
            fill: $white !important;
        }

        circle {
            stroke: $white !important;
        }
    }
}

#menu-0 #subMenu_3 {
    padding-left: 8px;
}

.active-sub-menu {
    // background-color: $activeSubMenu;
    // color: $white;
}

.sidenavHeight {
    padding-left: 10px;
    padding-right: 10px;
}

.sidenavHeight .icon-box svg {
    width: 15px;
    height: 15px;
    z-index: 1;
}

.active-sub-menu>a {
    // color: $white !important;
    // color: $activeGreenColor;
    // border-top: 1px solid $activeGreenColor;
    // border-bottom: 1px solid $activeGreenColor;
}

.menu-item-has-children.active-sub-menu li {
    // background: rgba(57, 63, 67, 0.7);
}

.menu-item-has-children.active-sub-menu li.active-child-menu {
    // background: rgba(47, 52, 55, 0.7);
}

.menu-item-has-children.active-sub-menu li.active-child-menu a {
    // color: rgba(0, 161, 225, 0.7) !important;
}

.support-mail {
    margin-top: 30px;
    padding: 25px;
    text-align: center;
}

.support-mail p {
    font-size: 13px;
    color: $white;
}

.support-mail p a {
    color: $white;
}

.stat-widget-one .stat-content {
    margin-left: -54px;
    width: 100%;
}

.inline-block {
    display: inline-block;
}

.dashboard-chart {
    /*   margin-top: -28px  */
}

.copiedText {
    display: block;
    float: right;
    color: $grey-enable;
    font-size: 12px;
}

.searchIcon {
    background-image: url("./assets/img/inputSearch.svg");
    background-repeat: no-repeat;
    /*background-position: right;*/
    /*background-position: 187px 10px;*/
    background-position: left 14px top 9px;
    background-position-y: center;
    border: 1px solid $grey-enable;
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 2px;
    font-size: 14px;
    padding: 10px;
    /*text-align: center;*/
}

/*.stat-icon img{
    max-width: 20%;
    margin-left: 30px;
}*/

.active-child-menu svg-icon path {
    fill: #00a1e1;
}

.dropdown-menu-item {
    border: 1px solid $primaryColor-active;
    border-radius: 8px;
    width: 215px;
    text-align: center;
    background-color: $white;
    margin-left: 13px;
    margin-right: 13px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: $primaryColor-hover;
    font-size: 14px;
    padding: 4px;
    height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dropdown-menu-item:first-child {
    margin-top: 0px !important;
}

.sorting-header i {
    color: $grey6;
    display: block;
    margin-left: 5px;
}

.commonTable .sorting-header {
    i.labelList {
        display: inline-block !important;
    }

    i {
        margin-left: 5px;
        color: $grey6;
    }
}

.sorting-header i.active-i {
    color: $grey-enable;
}

/* Datepicker */

.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
}

.custom-day.focused {
    background-color: $grey6;
}

.custom-day.range,
.custom-day:hover {
    background-color: rgb(2, 117, 216);
    color: $white;
}

.disabled .custom-day:hover {
    background-color: unset !important;
    color: $grey-enable !important;
}

.custom-day.faded {
    background-color: rgba(2, 117, 216, 0.5);
}

.disabled .custom-day.faded {
    background-color: unset !important;
    color: $grey-enable !important;
}


.form-inline .input-group>.form-control {
    width: 208px;
    font-size: 14px;
    border-radius: 4px !important;
    height: 40px;
    text-align: center;
}

.form-control {
    font-size: 14px;
    height: 40px !important;
    width: 100%
}

// .form-inline .input-group>.form-control {
//     width: 208px;
//     font-size: 14px;
//     border-radius: 4px !important;
//     height: 40px;
//     text-align: center;
// }

// .form-control {
//     font-size: 14px;
//     height: 40px !important;

//     &textarea {
//         font-size: 14px;
//         height: 40px !important;
//     }
// }


@media screen and (-webkit-min-device-pixel-ratio: 0) {

    /* this will target only Mac Safari and Chrome browsers */
    // .mac-os .form-inline .input-group>.form-control {
    // }
}

.input-group-addon {
    line-height: unset !important;
}

.disp-block {
    display: block !important;
}

.input-group .dropdown-menu {
    top: 33px !important;
}

.input-group .dropdown-menu li {
    text-align: center !important;
}

/* .input-group .dropdown-toggle {
  height: 39px;
} */

/*aside.left-panel {
    width: 15%;
    padding: 0;
    height: 100%;
    display: block;
    float: left;
}

.right-panel{
    width: 85%;
    float: right;
    display: block;
}*/

.right-panel {
    /*width:calc(100% - 220px);*/
    width: 86.2%;
}

a {
    text-decoration: none !important;
}

.navbar .navbar-nav li>a {
    font-size: 14px;
    box-sizing: border-box;
    height: 48px;
    line-height: 21px;
    color: white;
}

#featuresChart svg {
    /*position: relative;*/
    /*left: 23%;*/
    margin: 0 auto;
}

#featuresChart .nv-pieWrap .nvd3-svg {
    transform: translateY(20px) !important;
}

.btn-customBorder {
    border-color: $table-body-data !important;
    color: $table-body-data !important;

    &:disabled {
        background-color: $grey6;
    }
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: block;
    vertical-align: middle;
}

.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show>.btn-primary.dropdown-toggle,
.btn-primary:hover {
    color: inherit;
    background-color: inherit;
    background-image: none;
    border-color: none !important;
    box-shadow: none !important;
}

.btn-primary {
    background-color: $primaryBlue-color !important;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0;
    box-shadow: 0px 1px 2px 0px #0000000A !important;
    height: 40px !important;
    padding: 8px 16px 8px 16px;
    gap: 4px;
    border-radius: 4px;
    border: none !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    color: #ffffff !important;
    min-width: 120px;

    &:hover {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
            linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0 !important;
        box-shadow: 0px 1px 2px 0px #0000000A;


    }

    &:focus {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0 !important;
        border: 2px solid #005FCC;
        box-shadow: 0px 1px 2px 0px #0000000A;
    }


    &:active {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005BF0 !important;
        border: 2px solid #005FCC;
        box-shadow: 0px 1px 4px 0px #00000080 inset;

    }

    &:disabled,
    &.disabled {
        box-shadow: 0px 1px 2px 0px #0000000A;
        background-color: #F5F5F5 !important;
        border: 1px solid #E6E6E6 !important;
        color: #BBBBBB !important;
        pointer-events: none;
    }
}

.btn-default {
    background-image: none !important;
}

.btn-group.open .dropdown-toggle {
    box-shadow: none !important;
}

.btn-default:active,
.btn-default.active,
.open>.dropdown-toggle.btn-default,
.btn-default:hover,
.btn-default:focus,
.btn-default.focus .btn-default:hover,
.btn-default:focus {
    color: inherit;
    background-color: inherit;
    background-image: none;
    border-color: inherit !important;
    box-shadow: none !important;
}

.dropdown-menu,
ngb-datepicker {
    box-shadow:
        0 1px 5px 0 rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.dropdown-menu-item:hover {
    background-color: $primaryColor-hover;
    color: $white;
}

.ngb-dp-day.disabled,
.ngb-dp-day.hidden {
    color: $grey-enable;
}

.calendar-component input.form-control:read-only {
    background: $white;
    border-radius: 4px !important;
    padding-right: 45px !important;
}

#_datepicker {
    position: absolute;
    z-index: 1000;
    background-color: $white;
    right: 246px;
    height: 309px;
    top: -8px;
}

.exportDatepicker #_datepicker {
    position: absolute;
    z-index: 1000;
    background-color: $white;
    right: 260px;
    height: 309px;
    top: 40px;
}

@media screen and (min-width: 1365px) {
    #_datepicker {
        position: absolute;
        z-index: 1000;
        background-color: $white;
        right: 250px;
        height: 309px;
        top: -8px;
    }
}

@media screen and (max-width: 755px) {
    #_datepicker {
        position: absolute;
        z-index: 1000;
        background-color: $white;
        margin-left: -16px;
        height: 309px;
        top: 308px;
        right: unset;
    }
}

.footer {
    background: $white;
    float: left;
    color: $grey-active;
    width: 100%;
    bottom: 0px;
    left: 0px;
    position: absolute;
    line-height: 30px;
    border-top: 1px solid #dfe0e7;
}

.footer .pull-left {
    margin-left: 16px;
}

.footer .pull-right {
    margin-right: 16px;
}

.footer p {
    text-align: center;
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 13px;
}

.marginLeft-footer-0 {
    margin-left: 0px !important;
}

.avatar-wrapper {
    float: left;
    background-color: $lightgray;
    border-radius: 29px;
    height: 35px;
    width: 35px;
    text-align: center;
    margin-right: 5px;

    .userImg-div {
        display: flex;
        justify-content: center;
    }
}

.user-area .user-avatar {
    float: none;
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.user-avatar-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    /* display: inherit; */
    max-width: 300px;
}

.nvtooltip.xy-tooltip {
    border: 1px solid $grey-active;
    background: $white;
    color: $grey1;
    border-radius: 4px;
    padding: 14px;
}

.nvtooltip h3,
.nvtooltip table td.key {
    font-size: 14px !important;
}

.nvtooltip table td.value {
    font-size: 14px !important;
}

b,
strong {
    font-size: 20px;
    color: #26273b;
    font-weight: 900;
}

.tooltipVisibility b,
.tooltipVisibility strong,
.xy-tooltip b,
.xy-tooltip strong {
    font-size: 14px;
}

.dashboard-chart .nv-label text {
    fill: rgb(255, 255, 255) !important;
    font: 400 16px Arial;
}

#featuresChart .nv-series text {
    fill: $grey1;
}

.section-heading {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 900;
}

.page-title small {
    font-size: 70%;
}

.scnd-section-header {
    border-bottom: none;
    padding-bottom: 0;
}

.section-wrapper {
    border: 1px solid #e6e6e6;
    border-radius: 8px;
}

.section-wrapper-edge {
    border: 1px solid $lightgray;
    border-radius: 0px;
}

.section-wrapper-0 {
    border-top: 1px solid $lightgray;
    border-right: 1px solid $lightgray;
}

.section-wrapper-0r {
    border-top: 1px solid $lightgray;
    border-radius: 0px 0px 8px 0px;
}

.section-wrapper-sides {
    border-right: 1px solid $lightgray;
    border-left: 1px solid $lightgray;
}

.section-wrapper-left {
    border-left: 1px solid $lightgray;
}

.section-wrapper-right {
    border-right: 1px solid $lightgray;
}

.datefilter-menu .dropdown-toggle.input-group-addon {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

@media (max-width: 1120px) {
    .dashboard-chart {
        overflow-x: scroll;
    }

    /*.right-panel {width: 85%;
    float: right;
    position: relative;
    min-height: 100vh;}

.navbar {height: 100%;
    position: fixed; width: 15%;}*/
}

/* common for all */

.graph-title {
    display: inline-block;
    width: calc(100% - 15px);
    position: absolute;
    z-index: 1;
}

.textCenter {
    text-align: center;
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

.search-field-wrapper {
    width: 348px;
}

/*GM Css start here*/

/*@media (min-width: 1080px) and (max-width: 1600px){

    .right-panel {width: 85% !important;
    float: right;
    position: relative;
    min-height: 100vh;}

.navbar {height: 100%;
    position: fixed; width: 15% !important;}
}*/

@media (max-width: 767px) {
    /*.navbar {
        position: inherit !important;
        width: 100% !important;
    }*/
    /*.right-panel {width: 100% !important;}*/
    /*.right-panel .breadcrumbs {margin-top: 0px;} */
    /*aside.left-panel .navbar {margin-bottom: 0px !important;}
aside.left-panel .navbar .navbar-header {overflow: hidden !important;}
aside.left-panel .navbar .navbar-header {height: 50px !important;}
.support-mail {display: none !important;}
aside.left-panel .navbar .navbar-toggler {float: right; margin-top: 8px !important;}*/
}

.multiselect-dropdown .dropdown-btn {
    border: 2px solid $grey-enable !important;
}

.btn-outline-secondary {
    border-color: $grey-enable !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
    display: inline-block;
    top: 9px !important;
    width: 0 !important;
    height: 0 !important;
    border-top: 4px solid $grey-hover !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    position: relative !important;
    left: 3px !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
    display: inline-block;
    width: 0 !important;
    height: 0 !important;
    border-bottom: 4px solid $grey-hover !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    position: relative !important;
    top: 8px !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    border: 1px solid $grey-hover !important;
    margin-right: 4px;
    background: $grey-hover !important;
    padding: 0 5px;
    color: $white;
    border-radius: 2px;
    float: left;
    margin-bottom: 3px;
    margin-top: 3px;
}

/*GM CSS ends here*/

@media (max-width: 575.99px) {
    aside.left-panel {
        padding: 0 15px;
        width: 100%;
        position: relative !important;
    }

    aside.left-panel .navbar .navbar-header {
        overflow: hidden;
    }

    .support-mail {
        display: none;
    }

    .search-field-wrapper {
        margin-top: 10px;
        float: left;
    }

    .export-button {
        margin-top: 10px;
    }

    .right-panel {
        width: 100% !important;
        position: relative !important;
    }

    #left-panel {
        width: 100% !important;
        height: inherit;
        min-height: 50px;
        padding: 10px 0px;
    }

    .right-panel .breadcrumbs {
        margin-top: 0 !important;
    }

    .footer {
        position: relative;
        height: inherit;
        line-height: inherit;
        bottom: 0;
    }

    .active-menu>a {
        // padding: 5px 20px 5px 13px !important;
    }

    .active-menu>a,
    .active-sub-menu>a {
        // padding: 5px 20px 5px 13px !important;
    }

    .itemCount {
        // margin: 30px 15px 20px 0px !important;
    }
}

@media (min-width: 800px) {
    .navbar {
        /*position: fixed;
       width: 13%;*/
    }

    header.header-new {
        position: fixed;
        z-index: 1000;
    }

    // #left-panel {
    //     margin-top: 60px;
    //     width: 280px !important;
    // }
}

@media (min-width: 777px) and (max-width: 799px) {
    .footer {
        bottom: -73px;
    }

    .footer .pull-left {
        margin-left: 0px;
    }

    .footer .pull-right {
        margin-right: 0px;
    }

    .footer p {
        width: 100%;
    }
}

@media (max-width: 776px) {
    .footer {
        bottom: -73px;
    }

    .footer p {
        width: 100%;
    }

    .footer .pull-left {
        margin-left: 0px;
    }

    .footer .pull-right {
        margin-right: 0px;
    }

    .quesStatement {
        display: block;
    }
}

@media (min-width: 500px) and (max-width: 799px) {
    header.header-new {
        position: fixed;
        z-index: 1000;
    }

    #left-panel {
        /*width: 21% !important;*/
        /*padding-top: 77px;*/
        // margin-top: 60px;
    }

    /*.right-panel {float: none !important;}*/
}

.nv-axisMin-x text {
    font-weight: 100 !important;

    /*text-anchor: start !important;*/
}

// .pageDetail-linechart g.nv-axisMaxMin.nv-axisMaxMin-x.nv-axisMin-x text{
//   display: none!important;
// }
.testmeBarGraph g.nv-axisMaxMin.nv-axisMaxMin-x.nv-axisMin-x {
    /* display: none !important;*/
}

.nv-axisMax-x text {
    font-weight: 100 !important;
}

.nv-axisMin-y {
    display: none;
}

.card-header {
    // background-color: unset !important;
    border-bottom: unset !important;
    margin-top: 5px;
    height: 93px;
    box-sizing: content-box;
    background-color: unset !important;
}

.card-heading {
    font-weight: 700 !important;
    font-size: 16px !important;
}

/*GM CSS starts from here*/

.searchIcon {
    padding-left: 40px;
    padding-right: 15px;
    margin-top: 2px;
    font-size: 16px;
}

html {
    position: relative;
    min-height: 100%;
}

.right-panel .breadcrumbs {
    background-color: transparent;
}

.right-panel .page-header {
    background-color: transparent;
    padding: 0px;
}

.right-panel .page-header .breadcrumb {
    background-color: transparent;
}

.right-panel .breadcrumbs {
    padding: 0px;
}

.breadcrumb li a {
    color: #212529;
}

.breadcrumb li::before {
    display: none;
}

.breadcrumb li .fa-angle-right {
    margin: 0px 10px;
}

.itemCount {
    font-size: 13px;
    display: inline-block;
    /* margin: 20px 15px 20px 0px; */
    margin: 40px 15px 20px 0px;
}

.user-area .dropdown-toggle::after {
    margin-left: 0;
    position: relative;
    top: 0px;
    left: 5px;
}

#showMe .nv-legendWrap.nvd3-svg {
    transform: translate(-24px, 287px) !important;
}

#testMe .nv-legendWrap.nvd3-svg {
    transform: translate(-35px, 380px) !important;
}

.m-t-70 {
    margin-top: 70px;
}

.m-t-80 {
    margin-top: 80px;
}

aside.left-panel {
    width: 280px !important;
    position: fixed;
    top: 0;
    left: 0;
    // height: calc(100vh - 148px);
    z-index: 999;
    transition: all 0.3s;
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    background-color: $white;
    background-clip: border-box;
    border: none !important;
    border-radius: 4px !important;
    box-shadow: 0px 2px 2px 0px #2122263d !important;
    // box-shadow: 0px 0px 2px 0px #0000001f !important;
}

.container {
    width: 100% !important;
}

.right-panel {
    width: calc(100% - 280px);
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

@media (max-width: 575.99px) {
    .right-panel .breadcrumbs {
        padding-left: 15px;
    }

    aside.left-panel .navbar .navbar-nav li>a {
        padding-left: 18px;
    }

    header.header-new a .new-logo {
        width: 50%;
    }
}

@media (width: 576px) {
    .mac-os #main-menu>ul.nav.navbar-nav {
        width: 100% !important;
    }

    .mac-os img.new-logo {
        width: 30% !important;
    }
}

/*GM CSS ends from here*/

.tableWidth_100 td,
.tableWidth_100 th {
    margin-bottom: 0px;
    width: 100%;
}

.table td,
.table th {
    font-size: 14px;
    table-layout: fixed;
    border: none !important;
}

th,
td {
    white-space: nowrap;
}

.table {
    // width: 1026px !important;
    // width: 995px !important;
    width: 100% !important;
    display: inline-table;
    margin-bottom: 0px;
    margin-top: 30px;
    // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border: 1px solid #e6e6e6;
    border-radius: 4px;
}

.table.commonTable,
.table {
    // overflow-x: auto;
    // display: table;
    // max-width: 100%!important;

    tbody tr {
        // border-bottom: 1px solid $table-lower-border;
        width: 100%;
        display: inline-table;
        height: 56px
    }

    thead tr {
        border-bottom: 1px solid $table-lower-border;
        width: 100%;
        height: 48px // display: inline-table;
    }
}

#downloadType span:nth-child(1),
#downloadType span:nth-child(3) {
    display: inline-block;
}

/*.card{
    cursor: pointer;
}*/

.nvtooltip {
    line-height: 1.5;
    padding: 3px !important;
    text-align: center;
}

.datefilter-menu .dropdown-menu {
    border: 1px solid $grey-enable !important;
}

.card-body {
    padding: 0.75rem 1.25rem 1.25rem 1.25rem !important;
    margin-top: 5px;
    overflow: auto;
}

.card-body-dashboard {
    padding: 0.75rem 1.25rem !important;
}

.cardPadding-bt100 {
    padding-bottom: 100px !important;
}

.cardPadding-bt50 {
    padding-bottom: 50px !important;
}

.nopadding {
    padding: 0 !important;
}

.navbar .navbar-nav li.menu-item-has-children a:before {
    top: 13px;
    margin-top: 0;
}

input::-webkit-input-placeholder {
    font-size: 16px;
}

.nv-noData {
    dominant-baseline: text-before-edge;
}

.surveyGraph .nv-noData {
    dominant-baseline: unset !important;
}

.datefilter-menu {
    margin-top: -10px;
}

.input-group-addon {
    border: 1px solid $grey-enable !important;
    background-color: $grey6 !important;
    color: $fontgrey;
}

/*.overlay-loader {
    position: absolute;
    background: rgba(255, 255, 255, 0.70);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3
}*/

/*table {
  width: 100%;
}*/

.loaderBackground {
    background-color: $white;
}

.guidePlay-icon svg:not(:root) {
    height: 25px;

    path:first-child {
        fill: $lightgreen;
    }
}

.guidePlay-icon-table svg:not(:root) {
    height: 15px;
    position: relative;
    top: 2px;

    path:first-child {
        fill: $lightgreen;
    }
}

.table-truncate {
    position: relative;
}

@media screen and (min-width: 570px) {

    #dashboardSentiment table th:nth-child(1),
    #dashboardSentiment table td:nth-child(1) {
        width: 250px !important;
        max-width: 250px !important;

        .tableContent {
            // width: 250px !important;

            max-width: 250px !important;
            display: block !important;
            width: fit-content !important;
        }
    }

    #dashboardSentiment table th:nth-child(2),
    #dashboardSentiment table td:nth-child(2) {
        width: 200px !important;
        max-width: 200px !important;

        .tableContent {
            // width: 250px !important;

            max-width: 200px !important;
            display: block !important;
            width: fit-content !important;
        }
    }

    #dashboardSentiment table th:nth-child(4),
    #dashboardSentiment table td:nth-child(4) {
        width: 100px !important;
        max-width: 100px !important;

        .tableContent {
            // width: 100px !important;

            max-width: 100px !important;
            display: block !important;
            width: fit-content !important;
        }
    }

    #dashboardSentiment table th:nth-child(6),
    #dashboardSentiment table td:nth-child(6) {
        width: 180px !important;
        max-width: 180px !important;

        .tableContent {
            // width: 180px !important;

            max-width: 180px !important;
            display: block !important;
            width: fit-content !important;
        }
    }

    #sentimentTable table th:nth-child(1),
    #sentimentTable table td:nth-child(1) {
        width: 260px !important;
        max-width: 260px !important;

        .tableContent {
            // width: 300px !important;

            max-width: 260px !important;
            display: block !important;
            width: fit-content !important;
        }
    }

    #sentimentTable table th:nth-child(3),
    #sentimentTable table td:nth-child(3) {
        width: 100px !important;
        max-width: 100px !important;

        .tableContent {
            // width: 100px !important;

            max-width: 300px !important;
            display: block !important;
            width: fit-content !important;
        }
    }

    #sentimentTable table th:nth-child(4),
    #sentimentTable table td:nth-child(4) {
        width: 150px !important;
        // div{
        // width: 150px!important;
        // }
    }

    #sentimentTable table th:nth-child(5),
    #sentimentTable table td:nth-child(5) {
        width: 180px !important;
        max-width: 180px !important;

        .tableContent {
            // width: 200px !important;
            max-width: 180px !important;
            display: block !important;
            width: fit-content !important;
        }
    }

    #dashboardWorkflow table th:nth-child(1),
    #dashboardWorkflow table td:nth-child(1) {
        width: 550px !important;
        max-width: 550px !important;

        .tableContent {
            // width: 550px !important;

            max-width: 550px !important;
            display: block !important;
            width: fit-content !important;
        }
    }

    #dashboardWorkflow table th:nth-child(2),
    #dashboardWorkflow table td:nth-child(2) {
        width: 200px !important;
        max-width: 200px !important;

        .tableContent {
            // width: 200px !important;

            max-width: 200px !important;
            display: block !important;
            width: fit-content !important;
        }
    }

    #dashboardWorkflow table th:nth-child(3),
    #dashboardWorkflow table td:nth-child(3) {
        // width: 100px !important;
        // max-width: 100px !important;

        .tableContent {
            // width: 100px !important;

            max-width: 100px !important;
            display: block !important;
            width: fit-content !important;
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
    .table-truncate {
        .truncate__body-status {
            max-width: 70% !important;
        }
    }
}

.table-truncate {
    position: relative;

    .truncate__body-status {
        cursor: pointer;
        position: absolute;
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .statusChip {
        position: absolute;
        // right: 0px;
        float: right;
        height: 19px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 3px 10px;
        border-radius: 16px;
        font-size: 10px;
        // font-weight: 600;
        color: $fontgrey;
        white-space: nowrap;
        // text-transform: capitalize;
    }

    .published {
        background-color: $lightgreen;
    }

    .unpublished {
        background-color: $grey-enable;
    }

    .deleted {
        background-color: $salmon;
    }
}

table.loading thead,
table.loading tbody {
    display: table;
    width: 100%;
}

table.loading tbody {
    position: relative;
    width: 100%;
}

table.loading tbody:after {
    // position: absolute;
    width: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    // // background-image: url("assets/img/loading1.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 8em;
    content: "";
    z-index: 4;
    // width: 400px;
    // height: 400px;
}

div.graph-overlay:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 40px;
    background-color: rgba(255, 255, 255, 1);
    // // background-image: url("assets/img/loading1.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 4em;
    content: "";
    z-index: 4;
    width: 400px;
    height: 400px;
}

div.graph-overlayModal:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* margin-top: 40px; */
    background-color: $white;
    // // background-image: url("assets/img/loading1.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 4em;
    content: "";
    z-index: 4;
    width: 100%;
    height: inherit;
}

div.graph-overlayOverallExportModal:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* margin-top: 40px; */
    background-color: $white;
    // // background-image: url("assets/img/loading1.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 4em;
    content: "";
    z-index: 999;
    width: 100%;
    height: 280px;
}

div.graph-overlay1:after {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 40px;
    background-color: $white;
    /* // background-image: url(assets/img/img-loading.gif); */
    // background-image: url("assets/img/loading1.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 4em;
    content: "";
    z-index: 4;
    width: 548px;
    height: 200px;
}

#editForm div.graph-overlay:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 0px !important;
    background-color: rgba(255, 255, 255, 1);
    /* // background-image: url('assets/img/img-loading.gif'); */
    // background-image: url("assets/img/loading1.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 4em;
    content: "";
    z-index: 4;
    width: 400px;
    height: 400px;
}

.panel-default .card-header {
    margin-top: 0;
}

.panel-default .card-header .arrow-icon-dropdown {
    margin-top: 1px;
}

.fa-angle-down:before {
    margin-left: 1.5px;
}

.fa-angle-up:before {
    content: "\f106";
    margin-left: 1.5px;
}

#dashboardTiles .card {
    cursor: default !important;
}

#featuresChart svg:not(:root) {
    margin: auto !important;
    min-width: 300px;
}

@media (min-width: 1365px) {
    .mac-os .datefilter-menu {
        padding-right: 4px;
    }
}

@media screen and (max-width: 1256px) {
    .detailedGraph .nvd3-svg {
        width: 950px !important;
        padding-right: 50px !important;
    }

    .graphWidth .nvd3-svg {
        width: 550px !important;
        box-sizing: content-box !important;
        padding-right: 50px !important;
    }
}

@media (max-width: 575px) {
    .date-picker {
        right: 0px !important;
    }

    .guideme-date-picker {
        right: 0px !important;
    }

    .testme-date-picker {
        right: 0px !important;
    }

    .guidemeDetail-date-picker {
        right: 0px !important;
    }

    .testmeDetail-date-picker {
        right: 0px !important;
    }

    .m-t-80 {
        margin-top: 0px;
    }
}

/* @media (max-width: 575px){
    .datefilter-menu{
      
      margin-right: -2%;
    }
} */

@media (max-width: 454px) {
    header.header-new a .new-logo {
        width: 100%;
    }
}

@media (min-width: 1365px) {
    .mac-os .datefilter-menu {
        padding-right: 4px;
    }
}

.fixHeight {
    height: 370px;
}

@media (min-width: 1082px) {
    #dashboardTiles .col-lg-2 {
        flex: unset !important;
        max-width: 19.99% !important;
    }
}

.dashboard-content .nv-point-paths path,
.dashboard-content .nv-groups rect {
    cursor: pointer;
}

.tiles-border .nv-point-paths path,
.dashboard-content .nv-groups rect {
    cursor: pointer;
}

.guideme-table {
    max-height: 377px;
    min-height: 377px;
}

.showme-table {
    max-height: 381px;
    min-height: 381px;
}

.testme-table {
    max-height: 351px;
    min-height: 351px;
}

.date-picker {
    position: fixed;
    right: 14px;
    z-index: 1;
    margin-top: 15px;
}

.borderless td,
.borderless th {
    border: none;
}

.table-row {
    border-bottom-style: hidden;
}

.table-row-color {
    color: $table-font-link;
    margin-left: 88%;
    margin-top: 5%;
    white-space: nowrap;
}

.table-row-color1 {
    color: $table-font-link;
    white-space: nowrap;
}

.testme-pass {
    color: $lightgreen;
}

.testme-fail {
    color: rgba(243, 51, 69, 0.91);
}

.guide-played {
    font-size: 12px;
}

#guideme-detail .nv-x text {
    display: none;
}

.removeGrid .nvd3 .nv-axis line {
    stroke: unset !important;
    padding: 0;
}

.removeGrid .nv-legendWrap {
    display: none;
}

.tiles-border {
    border: 1px solid $lightgray;
    /* background-color: #a2a0a0; */
    background-color: $white;
    border-radius: 10px;
    /* box-shadow: 0 0 10px $grey-enable;*/
}

.table thead {
    // box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    display: inline-table;
    width: 100%;
}

.table thead th {
    /*    border-right-style: inset   */
    color: $table-header-font;
    border-bottom: none !important;
    font-size: 14px;
    table-layout: fixed;
    font-weight: 700;
    font-style: normal;
}

.table tbody td {
    /*    border-right-style: inset   */
    color: $table-body-data;
    line-height: 2;
    font-family: 400;
    font-size: 14px;

}

.table tbody tr:hover,
.table tbody tr:hover~td {
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.bg-secondary1 {
    background: #ffff !important;
}

.guideme-date-picker {
    /* position: fixed;
  right: 17px; */
    /* z-index: 1000; */
    z-index: 999;
}

.guidemeDetail-date-picker {
    position: fixed;
    right: 17px;
    margin-top: -25px;
    margin-right: -2px;
    z-index: 999;
}

.testme-date-picker {
    position: fixed;
    right: 17px;
    /* z-index: 1000; */
    z-index: 999;
    margin-top: 14px;
    margin-right: -2px;
}

.testmeDetail-date-picker {
    position: fixed;
    right: 17px;
    z-index: 1000;
    margin-top: -22px;
    margin-right: -2px;
}

.one_table tr:nth-child(odd) {
    background-color: $white;
}

.one_table tr:nth-child(even) {
    background-color: $white;
}

.one_table tr:nth-child(1) {
    background-color: $white;
}

.one_table tr:nth-child(0) {
    background-color: $white;
}

.color_table tr:nth-child(odd) {
    // background-color: $white;
}

.color_table tr:nth-child(even) {
    background-color: $table-row-even;
}

.color_table tr:nth-child(1) {
    // background-color: $white;
}

.color_table tr:nth-child(0) {
    // background-color: $white;
}

.color_table tr {
    // height: 48px !important;
}

.seperator {
    display: inline-block;
    width: 1px;
    border-left: 1px solid $grey-active;
    height: 12px;
    float: right;
    margin-top: 3px;
}

.removeGrid svg {
    width: 100% !important;
}

// .pagination-guideme {
//   width: calc(100% - 20px);
// }

// .pagination-guideme {
//   /*width: 1015px !important;*/
//   width: calc(100% - 20px) !important;
// }

// .pagination-visualizer {
//   width: 100%;
//   padding-left: 15px;
// }

// .pagination-visualizer .pagination {
//   display: inline-block;
//   padding-left: 0;
//   padding-right: 15px;
//   margin: 20px 0;
//   border-radius: 4px;
// }

.exportDatepicker {
    .guideme-date-picker2 {
        margin-top: 0px;
        margin-left: 0px;
        position: relative;
    }

    .datefilter-menu {
        margin-top: 0px;
    }
}

/* page */

.userStats-lineChart svg:not(:root) {
    margin: auto !important;
}

#timesChart svg:not(:root) {
    margin: 0 auto !important;
}

@media (max-width: 990px) {
    .tiles-border {
        margin-top: unset !important;
        margin-bottom: 5%;
    }

    .tiles-border1 {
        margin-bottom: 2%;
    }

    .topMargin {
        margin-top: 21px !important;
    }

    .removeMargin {
        margin-top: unset !important;
    }
}

.tiles-border .nvd3 .nv-point-paths path {
    cursor: default !important;
}

.one_table {
    /* margin-bottom: 0px; */
}

@media (min-width: 1365px) {
    .mac-os .datefilter-menu {
        padding-right: 4px;
    }
}

.refreshPadding {
    position: absolute;
    right: 0px;
}

.refreshTablePadding {
    position: absolute;
    right: -4px;
}

body {
    color: $table-header-font !important;
}

.datefilter-menu1 {
    margin-top: -6px;
    margin-right: 1px;
}

@media (max-width: 1268px) {
    .height-survey-table {
        height: 381px !important;
    }
}

@media (max-width: 1249px) {
    .pagination-page {
        width: 1020px !important;
    }
}

@media (max-width: 1275px) {
    .pagination-testme {
        width: 1015px !important;
    }
}

@media (max-width: 1295px) {
    .pagination-showme {
        width: 1015px !important;
    }

    .table {
        // width: 1026px !important;
        // width: 1200px !important;
        width: 100% !important;
        margin-bottom: 0px;
    }

    .guideSection .table {
        // width: 1026px !important;
        width: 1200px !important;
        display: block;
        overflow-x: auto;
        // width: 100% !important;
        margin-bottom: 0px;
    }

    .workflowSection .table {
        display: block;
        overflow-x: auto;
    }

    // .pagination {
    //   padding-right: 20px;
    // }
    // .paginationPosition {
    //   right: 20px;
    // }
    /*.inlineTable{
    display: inline-table;
  }*/
}

@media (max-width: 1255px) {
    .pagination-guideme {
        /*width: 1015px !important;*/
        /*width: calc(100% - 20px) !important;*/
    }
}

@media (max-width: 1249px) {
    .pagination-guide-detail {
        width: 1015px !important;
    }
}

@media (max-width: 1249px) {
    .pagination-testme-detail {
        width: 1015px !important;
    }
}

.truncate__body {
    position: absolute;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 50%;
    transform: translateY(-50%);
}

.table-truncate {
    position: relative;
}

@media (max-width: 500px) {
    .date-picker {
        margin-top: 0%;
    }
}

@media (min-width: 1256px) {
    .one_table {
        width: 100% !important;
    }
}

@media (max-width: 1255px) {
    .one_table {
        width: 490px !important;
        overflow-x: scroll;
        display: inline-table;
    }
}

@media (max-width: 1255px) {
    .guideme-table {
        margin-right: 5px;
    }

    .testme-table {
        margin-right: 5px;
    }

    .survey-table {
        margin-right: 5px;
    }
}

.guideme-date-picker2 {
    margin-top: -46px;
    margin-left: 12px;
}

.guideme-date-picker3 {
    margin-top: 15px;
    margin-right: -2px;
}

@media (max-width: 576px) {
    .header-new {
        position: fixed;
        z-index: 9999;
    }

    aside.left-panel {
        top: 72px;
    }

    #left-panel {
        padding-top: 0px !important;
    }
}

@media (min-width: 500px) and (max-width: 575px) {
    .guideme-date-picker1 {
        margin-right: 9px;
        margin-top: 18px;
    }

    .datefilter-menu1 {
        margin-top: 0px !important;
    }

    .guideme-date-picker2 {
        margin-top: 86px;
        margin-right: 10px;
    }

    .guideme-date-picker3 {
        margin-right: 10px;
        margin-top: 15px;
    }

    .guideme-date-picker4 {
        margin-right: 10px;
    }

    .guideme-date-picker5 {
        margin-right: 11px;
    }

    .guideme-date-picker6 {
        margin-right: 10px;
    }

    .guideme-date-picker7 {
        margin-right: 10px;
    }

    .testme-date-picker {
        margin-top: 19px;
    }

    .guideme-date-picker5 {
        margin-top: 19px !important;
        margin-right: 10px;
    }

    .guidemeDetail-date-picker {
        margin-top: -19px;
    }

    .testmeDetail-date-picker {
        margin-top: -16px;
    }
}

@media (min-width: 447px) and (max-width: 499px) {
    .guideme-date-picker1 {
        margin-right: 9px;
        margin-top: 16px;
    }

    .guideme-date-picker2 {
        margin-right: 10px;
        margin-top: 87px;
    }

    .guideme-date-picker3 {
        margin-right: 10px;
    }

    .guideme-date-picker4 {
        margin-right: 11px;
    }

    .guideme-date-picker5 {
        margin-right: 11px;
    }

    .guideme-date-picker6 {
        margin-right: 10px;
    }

    .guideme-date-picker7 {
        margin-right: 10px;
    }

    .datefilter-menu1 {
        margin-top: 0px !important;
    }

    .guideme-date-picker3 {
        margin-top: 85px;
    }

    .testme-date-picker {
        margin-top: 86px;
    }

    .guideme-date-picker5 {
        margin-top: 88px !important;
        margin-right: 10px;
    }

    .guidemeDetail-date-picker {
        margin-top: 48px;
    }

    .testmeDetail-date-picker {
        margin-top: 53px;
    }
}

@media (max-width: 767px) {
    .search-topmargin {
        margin-top: 4%;
    }

    .quesExport-btn-div {
        top: 90px;
    }
}

.guideme-date-picker5 {
    margin-top: 14px;
}

@media (max-width: 446px) {

    /*.guideme-date-picker1 {
    z-index: 999;
  }*/
    .guideme-date-picker2 {
        margin-right: 11px;
        margin-top: 6px;
    }

    .guideme-date-picker3 {
        margin-right: 10px;
        margin-top: 7px;
    }

    .guideme-date-picker4 {
        margin-right: 10px;
        margin-top: 8px;
    }

    .guideme-date-picker5 {
        margin-right: 10px;
        margin-top: 6px;
    }

    .guideme-date-picker6 {
        margin-right: 10px;
        margin-top: -35px;
    }

    .guideme-date-picker7 {
        margin-right: 10px;
        margin-top: -30px;
    }
}

@media (max-width: 446px) {
    .ngb-dp-months {
        width: 271px;
        overflow: auto;
    }

    ._testme_dp {
        margin-top: 124%;
    }

    ._testme_dp1 {
        margin-top: 112%;
    }

    ._testme_dp2 {
        margin-top: -9%;
    }

    .user-area .user-menu {
        z-index: 9999 !important;
    }
}

@media (min-width: 447px) and (max-width: 500px) {
    .ngb-dp-months[_ngcontent-c4] {
        width: 273px;
        overflow: overlay;
    }

    .date-picker-width2 {
        margin-top: 300px !important;
    }

    .date-picker-width3 {
        margin-top: 300px !important;
    }

    .date-picker-width4 {
        margin-top: -23px !important;
    }

    .date-picker-width5 {
        margin-top: 268px !important;
    }
}

@media (min-width: 501px) and (max-width: 755px) {
    .date-picker-width {
        margin-right: 457px;
    }

    .date-picker-width1 {
        margin-left: -214px !important;
        margin-top: -6px;
    }

    .date-picker-width2 {
        margin-top: 300px !important;
        margin-left: -230px !important;
    }

    .date-picker-width3 {
        margin-top: 300px !important;
        margin-left: -230px !important;
    }

    .date-picker-width4 {
        margin-left: -230px !important;
        margin-top: -23px !important;
    }

    .date-picker-width5 {
        margin-left: -230px !important;
        margin-top: 268px !important;
    }

    .date-picker-width7 {
        margin-top: -6px !important;
    }
}

@media (max-width: 576px) {
    .footer {
        bottom: 0px;
    }

    .guideme-date-picker,
    .testme-date-picker {
        z-index: 1;
    }
}

.org-active-row {
    /* background-color: #00a1e1; */
    background-color: $primaryColor6;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
}

.org-row:hover {
    background-color: $primaryColor6;
    color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
    /* color: $white; */
    cursor: pointer;
}

// .orgData {
//     max-height: 50vh;
//     overflow-y: auto;
//     overflow-x: hidden;
//     min-height: 30vh;
//     margin-top: 20px;
// }

.verticalMiddle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 575px) {
    .question-export {
        margin-top: -30px;
    }
}

.ResendActive {
    cursor: pointer;
    color: $grey-enable;
}

.ResendInactive {
    color: #00a1e1;
}

@media (min-width: 576px) and (max-width: 1350px) {
    /* .datefilter-menu0 {
    margin-top: -8px;
    margin-right: -1px;
  } */
}

@media (min-width: 353px) and (max-width: 446px) {
    .guideme-date-picker2 {
        margin-top: 8px;
        margin-right: 10px;
    }

    .guideme-date-picker1 {
        margin-right: 9px;
        margin-top: -3px;
    }
}

@media (width: 575px) {
    .datefilter-menu0 {
        margin-top: -16px;
    }
}

@media (min-width: 777px) and (max-width: 830px) {
    .yes-no {}

    .comment-type {}
}

.supportLink {
    color: $table-font-link !important;
    text-decoration: underline !important;
    font-size: 14px;
    font-weight: 600;
}

/*.nav.navbar-nav li:nth-child(3) a span{
    display: inline-block;
    position: relative;
    top: -4px;
}
.nav.navbar-nav > li:nth-child(3) .menu-icon-img{
    left: 3px;
}
.nav.navbar-nav li:nth-child(2) a img{
    top: -1px;
    left: 3px;
}*/

/*.nav.navbar-nav li:nth-child(2) .menu-icon-img,
.nav.navbar-nav li:nth-child(3) .menu-icon-img,
.nav.navbar-nav li:nth-child(4) .menu-icon-img{
    width: 26px;
    height: 20px;
}*/

.card {
    /* margin-top: 17px; */
    margin-top: 1.5em;
}

/* common css */

.demo {
    /*  margin-left: 8px;*/
    display: inline-block;
    padding-top: 80px;
    padding-bottom: 70px;
    padding-left: 15px;
    padding-right: 15px;
}

.activeUser-chart-div {
    background-color: $white;
}

.activeUser-chart-div path {
    stroke: steelblue;
    stroke-width: 2;
    fill: none;
}

.activeUser-chart-div .axis path,
.activeUser-chart-div .axis line {
    fill: none;
    stroke: $grey-enable;
    stroke-width: 1;
    shape-rendering: crispEdges;
}

.activeUser-chart-div label {
    position: absolute;
}

.activeUser-chart-div circle {
    cursor: pointer;
    fill: steelblue;
}

/* Retention */

.retentionChart text {
    font-family: "Lato", sans-serif;
    font-size: 12px;
    fill: $white;
}

.retentionChart #tooltip {
    position: absolute;
    width: 200px;
    height: auto;
    padding: 10px;
    background-color: $white;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    /*
    -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);*/
    /*box-shadow:  4px 4px 4px 10px rgba(0, 0, 0, 0.4) pointer-events: none;*/
}

.retentionChart #tooltip.hidden {
    opacity: 0;
}

.retentionChart #tooltip p {
    margin: 0;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    line-height: 20px;
}

#userTimeChart rect.bordered {
    stroke: $white;
    stroke-width: 1px;
}

.timesChart rect.bordered {
    stroke: $white;
    stroke-width: 1px;
}

.timesChart text.mono {
    font-size: 9pt;
    font-family: Consolas, courier;
    fill: $grey-enable;
}

.timesChart text.axis-workweek {
    fill: $black;
}

.timesChart text.axis-worktime {
    fill: $black;
}

.axis path,
.axis line {
    fill: none;
    stroke: $black;
    shape-rendering: crispEdges;
}

// .x.axis path {
//   display: none;
// }
.timesChart .line {
    fill: steelblue;
    stroke: steelblue;
    stroke-width: 1.5px;
}

/* CSS goes here. */

.subunit {
    fill: none;
    stroke: #797676;
    stroke-width: 1px;
}

text.subunit-label {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    text-anchor: middle;
    fill: $black;
}

.subunit-label {
    display: none;
}

.graticule {
    fill: none;
    stroke: $grey-enable;
    stroke-opacity: 0.5;
    stroke-width: 0.5px;
}

/* Languages */

.rating .bar {
    fill: $salmon;
}

.ratingql .bar {
    fill: $salmon;
}

.axis {
    font-size: 13px;
}

// .axis path,
// .axis line {
//   fill: none;
//   display: none;
// }
.label {
    font-size: 13px;
}

.d3-tip {
    /*font-family: Verdana;*/
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid $grey-enable;
    border-radius: 6px;
    padding: 8px;
    color: $black;
    z-index: 5070;
    font-size: 13px;
    box-shadow:
        0 1px 3px 0 rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12);
    transition: all 0.3s linear;
}

.maintenance-message-wrapper {
    margin: 100px;
}

.mainSection {
    min-height: 100%;
    display: block;
}

.message {
    position: absolute;
    top: 25%;
    left: 26%;
    border: 1px solid $lightgray;
    background-color: $white;
    border-radius: 10px;
    padding: 36px;
    text-align: center;
    right: 25%;
    overflow-x: auto;
    white-space: nowrap;
}

#blockChart {
    margin: auto;
    position: relative;
    padding: 20px;
    height: 100%;
    margin-top: 10px;
}

#blockChart>div {
    position: relative;
    height: 400px;
    width: 800px;
    margin: 0 auto;
}

#blockChart .node {
    line-height: 1;
    overflow: hidden;
    position: absolute;
    // background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    padding: 8px;
}

#bubbleChart svg:not(:root) {
    // width: 100% !important;
    // min-width: 960px;
}

.cardPadding-bt100 {
    padding-bottom: 100px !important;
    width: 100%;
}

.pathPagination {
    width: 100%;
}

// .pathPagination .paginationPosition {
//   position: static;
//   bottom: 0px;
//   right: 0px;
//   left: 0px;
// }

// .pathPagination .pagination {
//   display: inline-block;
//   padding-left: 0;
//   padding-right: 0px;
//   margin: 20px 0;
//   border-radius: 4px;
// }

/* pagination */

.tablePagination {
    float: right;
}

.tablePagination .page-item.active .page-link {
    z-index: 1;
    color: $white;
    background-color: $purple-active !important;
    border-color: $purple-active !important;
}

#deleteModal .modal-content {
    margin-top: 183px;
    width: 400px !important;
    margin-left: 60px;
    margin-right: 60px;
    border-radius: 4px;
}

#createSurvey .modal-dialog {
    max-width: 650px;
}

#editSurvey .modal-dialog {
    max-width: 650px;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}

.bar_pieText {
    font-size: 14px;
    /* position: absolute; */
    /* left: 851px; */
    /* top: 29%; */
    /* top: 320px; */
}

.pieChart-text {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    width: 250px;
}

@media (max-width: 1144px) {

    .pie-table-div,
    .barChart-div {
        display: block;
        max-width: 100%;
        width: 100%;
    }
}

.arrowUp-color {
    color: #14a914;
    font-weight: 600;
}

.arrowDown-color {
    color: #cc0808;
    font-weight: 600;
}

.switchApp-btn-icons {
    width: 15px !important;
    height: 15px !important;
    font-size: 16px;
    color: #000000
}

.faEditSideNav {
    position: absolute;
    right: 15px;
    width: 15px;
    height: 15px;
}

.faCheckSideNav {
    position: absolute;
    right: 15px;
    width: 15px;
    height: 15px;
    font-size: 16px !important;
}

.faEditSideNav .editTooltiptext {
    visibility: hidden;
    width: 70px !important;
    height: 20px !important;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    font-family: "Lato", sans-serif !important;
    font-size: 10px !important;
    font-weight: 0px !important;

    /* Position the tooltip */
    position: absolute !important;
    bottom: 160%;
    left: -80%;
    margin-left: -30px;
    z-index: 1;
}

.faEditSideNav .editTooltiptext::after {
    content: " ";
    position: absolute !important;
    top: 100%;
    /* At the bottom of the tooltip */
    left: 70%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.faEditSideNav:hover {
    .editTooltiptext {
        visibility: visible;
    }
}

.helpBlock {
    color: $brightred !important;
}

.switchApp-btn svg:not(:root) {
    width: 37px !important;
    height: 16px !important;
    margin-right: 0px !important;
    margin-bottom: 7px;
}

.switchApp-btn svg path {
    fill: $primaryColor-enable;
}

.switchApp-btn:hover svg path {
    fill: $white;
}

.switchApp-btn:disabled svg path {
    fill: $grey-active;
}

.switchApp-btn:disabled:hover svg path {
    fill: $grey-active;
}

.validateBtn {
    // background-color: #00a1e1;
    // font-size: 13px;
    // border: none;
    float: right;
    margin-left: 10px;
}

.cancelBtn {
    // font-size: 13px;
    float: right;
    margin-left: 10px;
}

.stepBtn {
    font-size: 13px;
    float: right;
    margin-left: 10px;
}

.validateBtn:disabled {
    // background-color: #00a1e1 !important;
    // opacity: 0.65 !important;
}

.categoryButton svg-icon path {
    fill: $white-shade2;
}

.cat-info svg:not(:root) {
    width: 16px !important;
    height: 12px !important;
}

.catTag-label svg-icon g,
.catWorkflow-label svg-icon g,
.selectTagChip svg-icon g {
    fill: $white;
}

#category_main_div .tooltip-inner {
    border: 1px solid $lightgray !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    max-width: 400px !important;
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 13px !important;
    background-color: $lightgray !important;
    text-align: left !important;
    opacity: 1 !important;
    color: $black;
    min-width: 0px;
}

#category_main_div .bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
#category_main_div .bs-tooltip-bottom .arrow:before {
    border-bottom-color: $lightgray !important;
}

@media (max-width: 799px) {
    .right-panel {
        width: calc(100% - 176px);
    }
}

// .modal-content {
//     top: 55px;
// }

@media (max-width: 1000px) {
    .modal-content {
        width: 800px !important;
    }
}

@media (max-width: 750px) {
    .modal-content {
        width: 600px !important;
    }
}

@media (max-width: 415px) {
    .modal-content {
        width: 350px !important;
    }
}

@media (max-width: 350px) {
    .modal-content {
        width: 300px !important;
    }
}

.analyticsLogo-div.dropdown-toggle::after {
    margin-left: 0;
    display: none !important;
    margin-top: 20px;
    margin-left: 8px;
    color: #c5c5ce;
}

.user-area .dropdown-toggle::after {
    display: none !important;
}

#chart rect.disabled+text {
    opacity: 0.5;
}

#donutchart rect.disabled+text {
    opacity: 0.5;
}

/*drop-off funnel steps*/

.steps-selectedOptns {
    padding: 0px;
}

.li-item {
    background-color: #d5eaf1;
    border-radius: 4px;
    padding: 5px 10px;
    border: 1px dotted #3a3a3a;
    margin-bottom: 12px;
    cursor: -webkit-grabbing;
    cursor: grabbing;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: block; */
    white-space: nowrap;
}

.li-item-icon {
    margin-right: 10px;
}

/* charts center */

#acquire-users svg,
#active_user svg {
    /*position: relative;*/
    /*left: 23%;*/
    /* margin: 0 auto; */
}

.d3-funnel-tooltip {
    font-weight: normal !important;
    border-radius: 4px !important;
}

.section-subheading-div {
    // margin-top: -25px;
    margin-top: -24px;
    // margin-left: 25px;
}

.section-subheading-div h5 {
    font-size: 14px !important;
    color: #555555;
    font-weight: 700;
}

#userPathChart {
    width: 730px;
    height: 430px;
}

#userPathChartLegend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    // padding: 0px 20px;
    width: 275px;
    height: 430px;
    overflow-y: auto;
    margin-top: 20px;

    // border: 1px $lightgray;
    // border-style: solid;
    // border-radius:2px;
    .userPathChartLegendRow {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 40px;

        .colorCircle {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            display: inline-block;
        }

        .stepCode {
            width: 20px;
        }

        .stepName {
            width: 210px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

#userTimeChart {
    width: 1000px;
    height: 430px;
}

#userTimeChart svg:not(:root) {
    margin: 0 auto;
}

#userTimeChart svg {
    position: relative;
}

.selected-item {
    padding-left: 28px !important;
    position: relative;
}

.selected-item::before {
    content: " ";
    // background-image: url(/assets/images/tag_icon_new.png) !important;
    width: 20px;
    position: absolute;
    height: 20px;
    background-repeat: no-repeat;
    color: $white;
    margin-right: 10px;
    left: 7px;
    top: 3px;
    background-size: 15px;
}

.multiselect-dropdown .disabled .dropdown-btn .selected-item a {
    display: none !important;
}

@media (max-width: 812px) and (min-width: 800px) {

    .footer .pull-left,
    .footer .pull-right {
        float: none !important;
    }
}

button:focus,
button:active {
    outline: none !important;
    box-shadow: none;
}

// .close span:hover {
//     width: 30px;
//     height: 30px;
//     display: flex;
//     align-items: center;
//     padding: 0px 9px 0px 9px;
//     border-radius: 50%;
//     background-color: #dedbdb;
//     box-sizing: border-box;
//     transition: all 0.3s ease-in-out;
//     justify-content: center;
// }

.close span {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 2px 13px 3px 11px;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    font-size: 20px;
    color: $grey;
}

.fa-refresh {
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: flex !important;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 11px;
    border-radius: 50%;
    /* background-color: #dedbdb; */
    box-sizing: border-box;
    font-size: 16px !important;
    // margin-top: -7px;
    margin-right: -10px;
    float: right;
}

.pinToDashboard {
    width: 35px;
    height: 35px;
    display: -webkit-box;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 0.6em;
    padding-left: 11px;
    padding-right: 11px;
    border-radius: 50%;
    /* background-color: #dedbdb; */
    box-sizing: border-box;
    font-size: 16px !important;
    // margin-top: -7px;
    margin-left: 47.1em;
    margin-right: -20px;
}

.btn-export {
    color: $grey-active;
    border: 1px solid $grey-active;
    background-color: $white;
    height: 34px;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin: 0 auto;
    width: auto !important;
    height: 35px;

    &:hover {
        color: $grey-active;
        border: 1px solid $grey-active;
        background-color: $white;
    }

    &:disabled {
        border: 1px solid $grey6;
        background-color: $grey6;
    }
}

.excel-icon {
    margin-right: 5px;
}

// .btn-export:focus {
//     color: $white;
//     background-color: #585869;
//     /*//border-color: #D8D8D8;*/
// }

// .btn-export:active,
// .btn-export:hover {
//     background-color: #585869;
//     color: $white;
//     border-color: #80bdff;
// }

/* .fa-refresh:hover{
  background-color: #aea7a7;
}*/

@media (max-width: 576px) {
    .siteLayout-block {
        height: 100vh;
    }

    .right-panel {
        height: calc(100vh - 200px);
        min-height: auto;
    }

    #category_main_div {
        min-height: auto;
    }

    .footer {
        height: 78px;
    }
}

.datepicker-position .alert-danger {
    opacity: 1;
    /* display: block; */
    position: fixed;
    top: 460px;
    right: 289px;
    z-index: 9999;
    font-size: 13px;
    font-weight: 600;
    /* display: flex!important; */
    align-items: center;
    width: 474px;
}

.leftBorder {
    border-left: 1px solid lightgrey;
}

.rightBorder {
    border-right: 1px solid lightgrey;
}

.borderBottom {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.borderBottom8 {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.borderTop {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

.borderTop8 {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.borderTopLeft {
    border-radius: 8px 0px 0px 0px;
}

.borderTopRight {
    border-radius: 0px 8px 0px 0px;
}

.borderBottomRight {
    border-radius: 0px 0px 8px 0px;
}

.borderBottomLeft {
    border-radius: 0px 0px 0px 8px;
}

.top-border-radius {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.lowerborder {
    border-bottom: 1px solid $table-lower-border;
    padding-top: 0px !important;
}

.bottom-border-radius {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.sectionTitle {
    padding: 8px 20px;
    margin-bottom: 0px;
    background-color: $section-header;
    border-radius: 8px 8px 0px 0px;
    color: $table-header-font !important;
    font-size: 18px;
    // border: 1px solid $lightgray;
}

.common-tooltip-card .tooltip.show .tooltip-inner {
    max-width: 390px;
}

.workflowIcon #Page-1 #workflo {
    fill: $fontgrey;
}

.responsesDropdown {
    margin-top: 5px !important;
}

// .nv-area {
//   fill: inherit !important;
// }
// .nvd3 .nv-groups path.nv-line {
//   fill: inherit !important;
// }
// .nv-line {
//   fill: inherit !important;
// }
.ellipsisText120 {
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.ellipsisText {
    width: 75px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.ellipsisTextUserMenu {
    width: 205px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.statusChip {
    right: 0px;
    top: 6px;
    position: relative;
    padding: 4px 10px;
    border-radius: 16px;
    font-size: 10px;
    // text-transform: capitalize;
    width: auto;
    // display: block;
    background-color: rgb(187, 187, 187);
    color: rgb(255, 255, 255);
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
}

.statusChipTable {
    cursor: default !important;
}

.spacing {
    margin: 5px;
    display: block;
}

.tabSwitch-btn {
    color: #ecedf3;
    border-color: #ecedf3;
    background-color: #585869;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: none;
    font-size: 13px;
    font-weight: 600;
    min-height: 35px;
    max-height: 35px;
    border-style: double;
    border-top-style: none;
    border-bottom-style: none;
    /* border: 1px solid #48455d; */
    /* border-radius: 6px; */
}

.btnActive {
    background-color: #393f43;
}

.tableTooltip .tooltip-inner {
    background-color: $section-tooltip-bg !important;
    border: 1px solid $section-tooltip-border !important;
    text-align: left !important;
    opacity: 1 !important;
    color: $black;
    min-width: 0px;
    padding-bottom: 16px;
    margin-top: 3px !important;
}

.tableTooltip .bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.tableTooltip .bs-tooltip-bottom .arrow:before {
    border-bottom-color: $section-tooltip-border !important;
    left: 0px !important;
}

.tableTooltip .bs-tooltip-bottom {
    background-color: transparent !important;
}

.min-height400 {
    min-height: 400px;
}

.marginTop0-5em {
    margin-top: 0.5em;
}

.marginTop-0 {
    margin-top: 0px;
}

.lineHeight-1 {
    line-height: 1;
}

.lineHeight-2-2 {
    line-height: 2.2;
}

.tableMain-div {
    margin-top: 20px;
    font-size: 13px;
}

.stepsForm-div {
    padding: 20px;
}

// .stepsForm-div button[disabled] {
//     background-color: $grey6
//     border: 1px solid $grey6
// }

/* Funnel step box */

.partition {
    height: 360px;
    display: flex;
}

.funnelheight-div {
    width: 100%;
    height: 100%;
    display: flex;
}

.funnelheight {
    height: 400px;
}

.resetFunnel-btn {
    // background-color: #00a1e1;
    // border: 1px solid #00a1e1;
    // color: $white;
    // font-size: 12px;
    // padding: 5px 10px;
    // border-radius: 4px;
    // position: absolute;
    // right: 80px;
}

.errorMsg {
    font-size: 13px;
    padding-left: 0px;
    color: $error-red;
}

#appTableSummary thead tr th:nth-child(1),
#appTableSummary tbody tr td:nth-child(1) {
    width: 400px !important;
    max-width: 400px !important;

    .appText {
        width: 400px !important;
        max-width: 400px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis !important;
    }
}

#appTableSummary thead tr th:nth-child(2),
#appTableSummary tbody tr td:nth-child(2) {
    width: 20% !important;
    max-width: 20% !important;
}

#appTableSummary thead tr th:nth-child(3),
#appTableSummary tbody tr td:nth-child(3) {
    width: 20% !important;
    max-width: 20% !important;
}

#appTableSummary thead tr th:nth-child(4),
#appTableSummary tbody tr td:nth-child(4) {
    width: 20% !important;
    max-width: 20% !important;
}

#appTableUsageTime thead tr th:nth-child(1),
#appTableUsageTime tbody tr td:nth-child(1) {
    width: 40% !important;
    max-width: 40% !important;

    .appText {
        width: 40% !important;
        max-width: 40% !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis !important;
    }
}

#appTableUsageTime thead tr th:nth-child(2),
#appTableUsageTime tbody tr td:nth-child(2) {
    width: 20% !important;
    max-width: 20% !important;
}

#appTableUsageTime thead tr th:nth-child(3),
#appTableUsageTime tbody tr td:nth-child(3) {
    width: 20% !important;
    max-width: 20% !important;
}

#appTableUsageTime thead tr th:nth-child(4),
#appTableUsageTime tbody tr td:nth-child(4) {
    width: 20% !important;
    max-width: 20% !important;
}

// @media screen and (min-width: 1250px) {
//     table {
//         width: 100% !important;
//     }
//     table th:nth-child(1) {
//         width: 40%;
//     }
//     table th:nth-child(2) {
//         width: 20%;
//     }
//     table th:nth-child(3) {
//         width: 20%;
//     }
//     table th:nth-child(3) {
//         width: 20%;
//     }
// }

// @media screen and (max-width: 1249px) {
//     table th:nth-child(1) {
//         width: 40%;
//     }
//     table th:nth-child(2) {
//         width: 20%;
//     }
//     table th:nth-child(3) {
//         width: 20%;
//     }
//     table th:nth-child(3) {
//         width: 20%;
//     }
// }

.color_table thead th {
    /*background-color: $white;*/
    /*border-bottom-color: $white;*/
    background-color: $table-header;
    color: $table-header-font;
    border-top: transparent !important;
}

.th-left-border {
    border-radius: 4px 0px 0px 0px;
}

.th-right-border {
    border-radius: 0px 4px 0px 0px;
}

.workflowCrumbs {
    text-decoration: none !important;
    outline: none !important;
    color: #656765 !important;
}

.stepsDiv {
    display: block;
    width: 100%;
    padding-right: 22px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.stepsCount {
    margin-left: 0px;
    font-size: 16px;
}

.guideTitle-text {
    font-size: 24px;
    margin-left: 10px;
}

.automatorCal {
    .guideme-date-picker2 {
        margin-top: -82px !important;
        // margin-left: 75px!important;
    }

    .datepicker-position {
        position: fixed;
        z-index: 999;
        right: 22px;
    }
}

.funnelDiv {
    // min-height: 150px;
    // height: 500px;
    // padding-bottom: 50px !important;
}

#funnelLabels {
    // margin-left: 150px;
    width: 40%;
}

#summary .nv-legendWrap.nvd3-svg {
    transform: translate(36px, 180px) !important;
}

#summary .nv-pieWrap.nvd3-svg {
    transform: translate(29px, -44px) !important;
}

#user_stats .nv-legendWrap.nvd3-svg {
    transform: translate(175px, 10px) !important;
}

#user_stats .nv-pieWrap.nvd3-svg {
    transform: translate(-50px, 0px) !important;
}

#user_stats .nv-wrap .nv-pieChart {
    transform: translate(0px, 30px) !important;
}

// for pages pie legends
@for $i from 1 through 8 {
    #user_stats .nv-legendWrap.nvd3-svg .nv-series:nth-child(#{$i}) {
        transform: translate(0px, $i * 20px) !important;
    }
}

.tabelEditing table tbody tr td:first-child {
    // .ellipsistext {
    //     color: $table-font-link;
    //     cursor: pointer;
    // }
}

// sentiment-details pie
// .sentimentPie-chart svg:not(:root) {
//     margin-left: -20px;
// }

// .exportModal {
.vl {
    border-left: 2px solid $grey-enable;
    height: 100px;
    margin-top: 6px;
}

.text {
    position: absolute;
    left: 50%;
    top: 25px;
    transform: translateX(-50%);
}

.downloadCsv {
    // padding: 50px;
    width: 50%;
    right: 16px;

    span {
        // color: $grey1 !important;
        // border: 1px solid $grey-enable;
        background-color: $white;
        padding: 6px;
        cursor: pointer;
        margin: 63.25px 112.5px;
        width: 120px;

        // &:hover {
        //     border: 1px solid $grey-enable !important;
        //     background-color: $white !important;
        // }
    }
}

.or-div {
    background-color: $white;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 8px);
    padding: 10px 0px;
    font-size: 14px;
    font-weight: 500;
}

// }

.noDomainWarning {
    margin-bottom: 8px;
    height: auto;
    padding: 4px;
    border: 1px solid #f79b42;
    border-radius: 4px;
    background-color: $white-shade2;
    background-size: cover;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    color: #f79b42;
    text-decoration: none solid rgb(247, 155, 66);
    text-align: center;
}



.error-display {
    display: block !important;
}

.disabledSect {
    opacity: 0.8;
    pointer-events: none;
}

// pages pagination
// .pages-section,
// .features-section {
//   .paginationPosition {
//     position: absolute;
//     top: 380px;
//   }
// }

// .workflow-section {
//   .paginationPosition {
//     position: absolute;
//     bottom: 0px;
//   }
// }

// .sentimentChk-box .custom-control-label::before {
//     border-radius: 4px;
//     background-color: #97c5f7;
// }
// #browserStatsChart .apexcharts-pie-label {
//     fill: $white;
//     text-shadow: none;
//     filter: none;
// }

.apexcharts-pie-label {
    fill: #000000 !important;
    text-shadow: none !important;
    filter: none !important;
}

#browserStatsChart .apexcharts-legend-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 60%;
    display: inline-block;
    overflow: hidden;
}

.apexcharts-text tspan {
    font-size: 12px;
}

.apexcharts-canvas {
    margin: 0px auto;
}

.apexcharts-legend-series {
    margin-top: 4px !important;
}

.apexcharts-tooltip.apexcharts-theme-dark {
    color: #000 !important;
    background-color: transparent !important;
    font-size: 12px;
}

.apexcharts-tooltip {
    border-radius: 4px;
}

.apexcharts-tooltip-series-group {
    display: flex;
    background-color: $white !important;
    border: 1px solid $black;
    border-radius: 4px;
    opacity: 0.9;
}

.apexcharts-tooltip-y-group {
    font-size: 13px !important;
}

.apexcharts-legend {
    right: 30px !important;
}

.apexcharts-xaxistooltip {
    display: none !important;
}

.editTitle {
    text-align: left;
    font-size: 13px;
    height: 35.5px;
    font-size: 1rem;
    line-height: 1.7;
    border: 1px solid $primaryColor-active;
    border-radius: 4px;
    padding: 2px 11px 5px 11px;
    margin-left: 8px;
    background-color: $white;
    color: $primaryColor-active;
}

.modalTitleEdit {
    border: 1px solid $grey-enable;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    max-width: 860px;
    max-height: 200px;
    // width: 860px;
    // height: 35px;
}

.greyBackGround {
    background-color: $grey6;
}

// dashboard pie chart
#activity_chart .nvd3.nv-pie path {
    fill-opacity: 1;
    cursor: pointer;
}

.pageTitle-div {
    padding: 10px 20px;
    border-bottom: 1px solid $white-shade2;
    // background-color: $white-shade2;
    border-top: 1px solid $white-shade2;
    display: block;
}

.tagModalText {
    font-size: 12px;
    color: $grey1;
    display: block;
}

.requiredStar12 {
    color: red;
    font-size: 12px;
}

.createPage-btn {
    background-color: $grey-focus;
    border-radius: 4px;
    color: $white;
    font-size: 13px;
    padding: 0 15px;
    height: 35px;
    position: absolute;
    margin-top: -10px;
    right: 16px;
    z-index: 1;
}

.createPage-btn[disabled] {
    background-color: $grey-hover;
    cursor: default !important;
}

.createPage-btn-square {
    background-color: $primaryColor-active;
    border-radius: 4px;
    display: flex;
    color: #ffffff;
    font-size: 13px;
    padding: 0 10px !important;
    height: 35px;
    position: absolute;
    right: 0px;
    z-index: 1;
    width: auto;
    text-align: center;
    align-items: center;
    float: right;
    margin-left: 10px;
    border: 0px;
    width: 120px;
    z-index: 0;

    i {
        color: $white;
        font-size: 16px;
        margin-right: 5px;
    }

    .fa-external-link-alt {
        font-size: 14px;
    }

    span {
        // margin-left: 10px;
        margin: 0 auto;
    }

    &:hover {
        background-color: $primaryColor-hover;
    }
}

.createPage-btn-square[disabled] {
    background-color: $grey-hover;
    cursor: default !important;
}

.deletePage-btn-square {
    background-color: $white;
    border-radius: 4px;
    display: flex;
    color: $red-enable;
    font-size: 13px;
    padding: 0 10px !important;
    height: 35px;
    position: relative;
    z-index: 1;
    width: auto;
    text-align: center;
    align-items: center;
    float: right;
    margin-left: 10px;
    border: 1px solid $red-enable;
    width: 120px;

    i {
        color: $red-enable;
        font-size: 16px;
        margin-right: 5px;
    }

    .fa-external-link-alt {
        font-size: 14px;
    }

    span {
        // margin-left: 10px;
        margin: 0 auto;
    }

    &:hover {
        color: $red-hover;
        border: 1px solid $red-hover;

        i {
            color: $red-hover;
        }
    }

    &:disabled {
        background-color: $white;
        color: $grey6;
        border: 1px solid $grey6;
        cursor: default !important;

        i {
            color: $grey6;
        }
    }
}

.secondary-btn-square {



    display: flex;
    /* padding: 0 10px !important; */
    height: 35px;
    position: relative;
    z-index: 1;
    padding: 15px;
    font-weight: 500;
    line-height: 1;
    color: #000 !important;
    border-radius: 4px;
    cursor: hand;
    border: 1px solid #d3d3d3 !important;
    text-align: left;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5019607843), #f5f5f5) !important;
    text-transform: capitalize;
    width: 180px;
    height: 40px;
    line-height: 0;
    padding: 0px 16px;

    &:hover {
        background: linear-gradient(0deg, #F5F5F5, #F5F5F5),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%),
            linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%) !important;
        border: 1px solid #BBBBBB !important;
        box-shadow: 0px 1px 2px 0px #00000014 !important;
    }

    &:focus {
        background: linear-gradient(0deg, #F5F5F5, #F5F5F5),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%) !important;
        border: 2px solid #005FCC !important;
        box-shadow: 0px 1px 2px 0px #00000014 !important;
    }

    &:active {
        background: linear-gradient(0deg, #F5F5F5, #F5F5F5),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%) !important;
        border: 1px solid #BBBBBB !important;
        box-shadow: 0px 1px 4px 0px #00000080 inset !important;
    }

    &:disabled,
    &.disabled {
        background: #F5F5F5 !important;
        border: 1px solid #E6E6E6 !important;
        box-shadow: 0px 1px 2px 0px #00000014 !important;
        color: #BBBBBB !important;
        pointer-events: none;
    }

    i {
        color: $grey-active;
        font-size: 16px;
        margin-right: 5px;
    }

    .fa-external-link-alt {
        font-size: 14px;
    }

    span {
        /* margin: 0 auto; */
        height: 40px;
    }

    &:hover {
        background-color: white;
        color: $grey-active;
        border: 1px solid $grey-active;

        i {
            color: $grey-active;
        }
    }
}

.pageTitleDescription {
    font-size: 12px;
    vertical-align: super;
    color: #777777;
}

.appIcon {
    width: 16px;
    border-radius: 4px;
    vertical-align: bottom;
    margin-right: 3px;
}

.verticalAlign {
    vertical-align: middle;
}

.tooltip-div {
    position: relative;
    top: -70px;
    z-index: 10;
}

.tooltipBody {
    color: black !important;
    background-color: $white !important;
    border: 1px solid $purple-active !important;
    position: absolute;
    // top: 100px;
    border-radius: 4px;
    width: 250px;
    padding: 8px;
    left: 0px;
    margin: 0px;
    font-size: 12px;
}

.labelChips {
    margin: 7px;
    position: inherit;
    white-space: pre;
    border: 4px solid $white;
    max-height: 33px;
    float: left;
}

.chipCount {
    color: $grey-active;
    vertical-align: super;
    font-size: 11px;
    height: 14px;
    width: 14px;
    padding-left: 4px;
    background-color: #f3ee4c;
    border-radius: 50%;
    display: inline-block;
}

.labelsDiv {
    // display: flex;
    // flex-wrap: wrap;
    height: 300px;
    max-height: 300px;
    // overflow: auto;
    padding: 10px 15px;
}

.chipOutline {
    outline: $grey-focus dashed 2px;
}

.warningMsg-text {
    color: #f79b42;
    display: inline-block;
    margin-top: 10px;
    float: left;
}

.lightGreyColor {
    color: $lightgray;
    display: inline-block;
    margin-top: 10px;
    float: left;
}

.submitLabels {
    margin-left: 10px;
    margin-right: 10px;
    box-sizing: border-box;
}

.clearBtn {
    // background-color: #7c7c80 !important;
    // border: 1px solid #7c7c80 !important;
    -webkit-box-align: center;
    // color: white!important;
    align-items: center;

    // line-height: 1;
    // padding: 5px;
    // width: 100px;
    // height: 30px;
    // font-size: 13px;
    &:hover &:active {
        // background-color: #7c7c80 !important;
        // border: 1px solid #7c7c80 !important;
    }
}

.chartDiv {
    .workflow-countBox {
        border: 1px solid $grey6;
        border-radius: 6px;
        padding: 25px 0px;
        margin-bottom: 20px;
    }

    .bestBox {
        border-bottom: 3px solid $color1;

        .bestTime-count {
            font-size: 20px;
            font-weight: 600;
            color: $color1;
        }
    }

    .avgBox {
        border-bottom: 3px solid $color2;

        .avgTime-count {
            font-size: 20px;
            font-weight: 600;
            color: $color2;
        }
    }

    .worstBox {
        border-bottom: 3px solid $color3;

        .worstTime-count {
            font-size: 20px;
            font-weight: 600;
            color: $color3;
        }
    }
}

.greyBorderTop {
    border-top: 1px solid $grey6;
}

.urlDiv {
    background-color: $grey6;
    border-radius: 4px;
    border: 1px solid $grey6;

    .focusBg {
        border-radius: 15px;

        &:hover {
            background-color: $white;
        }
    }

    .urlString {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.chkBox {
    background-color: initial !important;
    cursor: default !important;
    -webkit-appearance: checkbox !important;
    box-sizing: border-box !important;
    margin: 5px 10px 3px 4px !important;
    padding: initial !important;
    border: initial !important;
    height: auto !important;
    width: auto !important;

    &:before {
        content: none !important;
    }
}

.publicUrlInput {
    margin: 0px 10px 0px 10px;
    color: $fontgrey;
    /* height: 30px; */
    border-radius: 4px;
    border: 1px solid !important;
    padding: 5px;

    :focus {
        border: 1px solid !important;
    }
}

.sentimentTitle {
    background-color: $white !important;
}

.dateTimePicker {
    // input[type="datetime-local"]::-webkit-clear-button {
    //     display: none!important;
    // }
    // input[type="datetime-local"]::-ms-clear {
    //     display: none!important;
    // }

    input::-webkit-calendar-picker-indicator {
        display: block !important;
    }
}

.tooltipWidth .tooltip-inner {
    min-width: auto !important;
    // width: 300px;
}

.modalSaveBtn:focus {
    // color: $white;
    // background-color: #00A1E1;
    /*//border-color: #D8D8D8;*/
}

#labels {
    margin: 0px !important;

    .e-multiselect {
        left: 0px !important;
        top: 10px !important;
        width: calc(100% - 0px) !important;
        border: solid 1px $grey6 !important;
        border-radius: 4px !important;
        background-color: $white !important;
    }

    .e-dropdownbase {
        padding-left: 20px;
        padding-right: 20px;
        height: 35px;
        box-sizing: border-box;
    }

    .labelChips {
        padding-right: 23px;
        white-space: nowrap;
    }

    .e-chips-close {
        margin-left: -24px;
        margin-top: -2px;
    }

    .e-input-focus::before,
    .e-input-focus::after {
        background: unset !important;
    }

    span.e-chips-close.e-close-hooker {
        margin-top: -29px !important;
    }

    .e-multi-select-wrapper .e-chips {
        margin: 0 -13px 6px 0 !important;
    }
}

.modalSaveBtn:active,
.modalSaveBtn:hover {
    // background-color: #00A1E1;
    // color: $white;
    // border-color: rgb(28, 181, 241);
}

.modalSaveBtn {
    // color: $white;
    // border: 1px solid rgb(1, 146, 204);
    // background-color: #00A1E1;
    -webkit-box-align: center;
    align-items: center;
    line-height: 1;
    // padding: 5px;
    // width: 100px;
    // height: 30px;
    // // font-size: 13px;
}

.appName-span {
    width: 120px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.colorPickSelector {
    border-radius: 5px;
    width: 75px;
    height: 50px;
    cursor: pointer;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
    -ms-transition: all linear 0.2s;
    -o-transition: all linear 0.2s;
    transition: all linear 0.2s;
    background-color: transparent;
    position: absolute;
}

.colorPickSelector:hover {
    transform: scale(1.1);
}

.e-multi-select-wrapper .e-chips {
    background-color: unset !important;
    height: 40px !important;
}

.e-multi-select-wrapper {
    display: block !important;
    padding-right: 0px;
}

.e-chips-collection {
    width: 100% !important;
    display: inline-block !important;
    background-color: $lightgray !important;
}

.e-searcher {
    width: 100% !important;
    display: inline-block !important;
    // border-top: 1px solid #c3c3c3 !important;
}

.e-chipcontent .labelChips {
    line-height: 4;
    margin: 5px;
    white-space: pre;
    border: 2px solid $white;
}

.tabSwitch-btn1 {
    border-width: 1px;
    color: $primaryColor-enable;
    border: 1px solid $primaryColor-enable !important;
    background-color: $white;
    width: 135px;
    border-radius: 4px;
    height: 35px;
    opacity: 0.3;

    &:hover {
        color: $primaryColor-hover;
        border: 1px solid $primaryColor-hover !important;
        background-color: $white;
        opacity: 1;
    }
}

.btnActive,
.activeBtn-bg {
    color: $primaryColor-enable;
    border: 1px solid $primaryColor-enable;
    background-color: $white;
    opacity: 1;
}

.exportPdf-btn {
    //line-height: -1;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: transparent;
    border: none;
    font-size: 13px;
    min-height: 35px;
    max-height: 35px;
    color: $grey-active;
    border: 1px solid $grey-active;
    border-radius: 4px;
    background-color: $white;
    float: right;
    // margin-right: 260px;
    // margin-top: -55px;
    position: absolute;
    right: 0;
    width: 135px !important;

    .pdf-icon {
        margin-right: 5px;
    }

    &:hover {
        border: 1px solid $grey-active;
        background-color: $white;
    }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ DIV BORDERS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
.border-CR {
    border-radius: 0px 4px 0px 0px !important;
}

.border-center {
    border-radius: 0px 0px 0px 0px !important;
}

.border-BR {
    border-radius: 0px 0px 4px 0px !important;
}

.border-right {
    border-radius: 0px 0px 0px 4px !important;
}

.border-left {
    border-radius: 0px 0px 4px 0px !important;
}

.border-BLR {
    border-radius: 0px 0px 4px 4px !important;
}

// .active-sub-menu a :not(svg){
//   fill: #45bae9;
// }

.enabledBorder {
    border: 1px solid $primaryColor-enable;
}

.disabledBorder {
    border: 1px solid $primaryTransparent;
}

.buttonPosition {
    position: fixed;
    z-index: 999;
}

.title-icon svg g:not(svg),
.pagesTitle-icon .cls-1,
.workflowTitle-icon .cls-1,
.sectionTitle-icon .cls-1,
.sectionTitle-icon .st0,
.guideIcon .st0 {
    fill: $table-header-font;
}

.sectionTitle-icon svg path,
.sectionTitle-icon .svg-icon path,
.sectionTitle-icon .cls-1,
.sectionTitle-icon .cls_1 {
    fill: $table-header-font;
    stroke: transparent;
}

.active-sub-menu2 .st0 {
    fill: $grey-enable;
}

.st0 {
    fill: $active-icons !important;
}

li.active-menu {
    background-color: #ebf2fe;
    color: #555555 !important;
    border-radius: 4px;
}

li.active-sub-menu {
    background-color: #ebf2fe;
    color: #555555 !important;
    border-radius: 4px;
}

li.active-sub-menu2 {
    background-color: #ebf2fe;
    color: #555555 !important;
    border-radius: 4px;
}

li.active-menu:has(.active-sub-menu) {
    background-color: white;
}

li.active-sub-menu:has(.active-sub-menu2) {
    background-color: white;
}

// from style
body {
    height: 609px !important;
    background-color: #f5f5f5;
    overflow-x: hidden;
    // font-size: 1rem;
    font-family: "Lato", sans-serif !important;
}

.displayContents {
    display: contents;
}

.floatRight {
    float: right;
}

.positionCenter {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.positionCenter-marginAuto {
    position: relative;
    margin: auto;
}

.minHeight90 {
    min-height: 90px;
}

.minHeight340 {
    min-height: 340px;
}

.orgPriorityProcessing {
    margin-left: 36px;
    font-size: 20px;
    margin-bottom: 10px;
    z-index: 100;
    border-radius: 19px;
    color: $priority-info;
    background-color: $white;
    left: 0px;
    top: -2px;
}

#toastContainer {
    z-index: 9999;
    top: 80px;
    position: fixed;
    padding: 0 20px !important;
    left: calc(50% + 120px);
    transform: translateX(-50%);
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
    max-height: 35px;
    color: $white;
    border-radius: 4px;
    height: 40px;
    background-color: red;
    display: none;
    font-size: 14px;
    box-shadow:
        0 1px 8px 0 rgba(0, 0, 0, 0.2),
        0 3px 4px 0 rgba(0, 0, 0, 0.14),
        0 3px 3px -2px rgba(0, 0, 0, 0.12);
    /*animation: mymove 1s;*/
}

.toast-left-50-perct #toastContainer {
    left: 50%;
}

@keyframes mymove {
    from {
        top: 0px;
    }

    to {
        top: 80px;
    }
}

.successToast {
    background-color: rgb(89, 177, 89);
    text-align: center;
    margin: auto;
    line-height: 2.4;
}

.errorToast {
    background-color: rgb(185, 49, 49);
    text-align: center;
    margin: auto;
    line-height: 2.4;
}

#funnelChart .arc path {
    stroke: $white;
    border-radius: 4px;
}

.dropdown-menu {
    border-radius: 0;
    transform: none !important;
    font-size: 14px;
}

.modal-header {
    display: flex !important;
    border-radius: 6px 6px 0px 0px;

    .modal-title {
        float: left;
        width: 100%;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
        font-size: 20px !important;
        float: right;
    }

    .close {
        margin-right: -15px;
        float: right;
        border: none;
        background-color: transparent;
    }
}

.modal-body {
    position: relative;
    padding: 0px;
}

#container {
    margin: 0 auto;
    position: relative;
    width: 800px;
    overflow: visible;
}

.svg {
    width: 800px;
    height: 400px;
    overflow: visible;
    /*    position:absolute;*/
}

.grid .tick {
    stroke: $grey6;
    opacity: 0.3;
    shape-rendering: crispEdges;
}

.grid path {
    stroke-width: 0;
}

#tag {
    color: $white;
    background: $error-red;
    width: 150px;
    position: absolute;
    display: none;
    padding: 3px 6px;
    margin-left: -80px;
    font-size: 11px;
}

#tag:before {
    border: solid transparent;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    width: 0;
    border-width: 10px;
    border-bottom-color: $error-red;
    top: -20px;
}

.tooltip {
    /*background-color: red!important;*/
}

.max50 {
    max-width: 50%;
}

#browserStatsChart {
    .apexcharts-legend {
        right: 20px !important;
        width: 120px;
        overflow: visible;
    }

    svg:not(:root) {
        overflow: visible;
    }

    foreignObject {
        overflow: initial;
    }
}

@media (max-width: 1165px) {
    .max50 {
        max-width: 100%;
    }
}

// .category-icon svg:not(:root) {
//   margin-top: 5px;
//   path:first-child {
//     fill: #1c85d0;
//   }
// }

#exp_span {
    font-size: 14px;
    margin-left: 20px;
    display: none;
}

#overlayCursor {
    width: calc(100% + 280px);
    position: absolute;
    height: 100vh;
    background-color: transparent;
    top: 0px;
    left: -280px;
    z-index: 9999;
    cursor: not-allowed;
    display: none;
}

.source-list1,
.source-list2,
.source-list3 {
    background-color: $white;
    color: $primaryColor-active;
    height: 35px;
}

.source-list1:hover,
.source-list2:hover,
.source-list3:hover {
    background-color: $primaryColor-hover;
    color: $white;
}

// pagination
.page-item.disabled .page-link {
    border-radius: 4px !important;
    margin-right: 2px !important;
    margin-left: 2px !important;
    min-width: 36px !important;
}

.tablePagination .page-item.active .page-link {
    z-index: 1;
    color: $primaryColor-active;
    background-color: $white !important;
    border: 2px solid $primaryColor-active !important;
    border-radius: 4px !important;
    margin-right: 2px !important;
    margin-left: 2px !important;
    min-width: 36px !important;
}

.tablePagination .page-item .page-link {
    border-radius: 4px !important;
    margin-right: 2px !important;
    margin-left: 2px !important;
    min-width: 36px !important;
}

.appContainer {
    &:hover {
        border: 1px solid $primaryColor-active !important;
    }
}

.modal-save-btn {
    background-color: $primaryColor-active !important;
    color: $white;
    border: 1px solid $primaryColor-active !important;
    width: 120px;
    font-size: 14px;
    height: 35px;
    border-radius: 4px;

    &:hover {
        background-color: $primaryColor-hover;
        cursor: pointer;
        color: $white;
    }

    &:disabled {
        background-color: $white;
        color: $grey6;
        border: 1px solid $grey6;
        cursor: default;
    }
}

.modal-save-btn-sm {
    background-color: $primaryColor-active;
    color: $white;
    border: 1px solid $primaryColor-active;
    width: 100px;
    font-size: 14px;
    height: 35px;
    border-radius: 4px;
    padding: 1px 6px 1px 6px !important;

    &:hover {
        background-color: $primaryColor-hover;
        cursor: pointer;
        color: $white;
    }

    &:disabled {
        background-color: $white;
        color: $grey6;
        border: 1px solid $grey6;
        cursor: default;
    }
}

.modal-cancel-btn {
    background-color: $white;
    color: $fontgrey;
    border: 1px solid #acadc1 !important;
    min-width: 120px;
    font-size: 14px;
    height: 35px;
    border-radius: 4px;

    &:hover {
        border: 1px solid $grey-hover !important;
        background-color: $white !important;
        color: $fontgrey !important;
        cursor: pointer;
    }

    &:disabled {
        background-color: $white;
        color: $grey6 !important;
        border: 1px solid $grey6 !important;
        cursor: default !important;
    }
}

.modal-cancel-btn-sm {
    background-color: $white;
    color: $fontgrey;
    border: 1px solid $grey-enable;
    min-width: 100px;
    font-size: 14px;
    height: 35px;
    border-radius: 4px;
    padding: 1px 6px 1px 6px !important;

    &:hover {
        border: 1px solid $grey-hover !important;
        background-color: $white !important;
        color: $fontgrey !important;
        cursor: pointer;
    }

    &:disabled {
        background-color: $white;
        color: $grey6 !important;
        border: 1px solid $grey6 !important;
        cursor: default !important;
    }
}

.modal-addMore-btn {
    background-color: $white;
    color: $primaryColor-enable;
    border: 1px solid $primaryColor-enable;
    min-width: 120px !important;
    width: 120px !important;
    margin: 20px 4px 10px 4px !important;
    font-size: 14px;
    height: 35px;
    border-radius: 4px;

    &:hover {
        background-color: $white;
        color: $primaryColor-hover;
        border: 1px solid $primaryColor-hover;
        // cursor: pointer;
    }

    &:disabled {
        background-color: $white;
        color: $grey6;
        border: 1px solid $grey6;

        .fa-plus {
            border: 1px solid $grey6;
        }

        // cursor: default;
    }
}

.createCat-button {
    background-color: $primaryColor-active;
    color: $white;
    border: 1px solid $primaryColor-active;

    &:hover {
        background-color: $primaryColor-hover;
        cursor: pointer;
    }

    &:disabled {
        cursor: default !important;
        opacity: 0.7;
    }
}

.entries-dropdown {
    background-color: $white;
    color: $grey1;
    border: 1px solid $grey6;
    height: 30px;
    width: 100px;
}

.entries-div {
    margin-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
}

.entries-container {
    display: flex;
    align-items: flex-end;
}

.checkmarkBox-color {
    border: 1px solid $grey6;
    background-color: $white !important;
    min-width: 18px;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked~.checkmark {
    background-color: $primaryColor-active !important;
    border: 1px solid $primaryColor-active;
}

/* On mouse-over, add a grey background color */
.check-container:hover input:checked~.checkmark {
    background-color: $primaryColor-hover !important;
    border: 1px solid $primaryColor-hover;
    cursor: pointer;
}

.check-container input:disabled~.checkmark {
    background-color: $grey6 !important;
    border: 1px solid $grey6;
    cursor: default;
}

.check-container:hover input:disabled~.checkmark {
    background-color: $grey6 !important;
    border: 1px solid $grey6;
}

// tooltip
.checkedCheckmark {
    background-color: $primaryColor-active !important;
    border: 1px solid $primaryColor-active;
}

.linkBtn {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    border-radius: 6px;

    i {
        margin-left: -1px;
        margin-top: 2px;
        font-size: 12px;
    }
}

.datefilter-menu .dropdown-toggle:empty::after {
    display: none !important;
}

// .commonTable {
// display: contents;
// }

// input:focus,
// textarea:focus {
//   box-shadow: none !important;
//   border: 1px solid $primaryColor-active !important;
//   outline-offset: 0px !important;
//   outline: none !important;
// }

// button:focus,
// select:focus {
//     box-shadow: none !important;
//     border: 1px solid $filter-btn-border !important;
//     outline-offset: 0px !important;
//     outline: none !important;
// }

.modal-header {

    button:focus,
    select:focus {
        box-shadow: none !important;
        border: 1px solid transparent !important;
        outline-offset: 0px !important;
        outline: none !important;
    }
}

.openModal-btn {
    background-color: $primaryColor-active;
    border-color: $primaryColor-active;
    border: 0;

    &:hover {
        background-color: $primaryColor-hover;
        border-color: $primaryColor-hover;
    }
}

table {
    //   table-layout: fixed;
}

.tableInherit table {
    // table-layout: inherit!important;
}

.selectCustomColor option:checked {
    // background: $activeGreenColor;
    // color: $white;
}

.initialsDiv {
    width: 39px;
    height: 39px;
    border-radius: 50%;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: $black;
    text-transform: uppercase;
}

.modal-width .modal-md .modal-content,
.modal-width .modal-md {
    max-width: 600px !important;
    width: 600px !important;
}

.bg-white {
    background-color: $white !important;
}

.w-60 {
    width: 60%;
}

.w-50 {
    width: 50%;
}

.loginHead {
    color: $primaryColor-active !important;
    font-weight: 400;
    text-align: center;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.theme-color,
h3 {
    color: $primaryColor-active !important;
}

.subLink-2 a .menu-icon-img .st0 {
    fill: $lightgray !important;
}

.subLink-1 a .menu-icon-img svg path {
    fill: $lightgray;
}

.mainLink,
.section-heading-div,
.tooltipFilter {
    text-transform: capitalize;
}

.mainLink a .menu-icon-img svg g,
.mainLink a .menu-icon-img .st0,
.mainLink a .menu-icon-img svg path,
.mainLink a .menu-icon-img .cls-1 {
    fill: $lightgray;
}

.mainLink a .menu-icon-img svg g #intelligence path {
    stroke: $lightgray;
}

.active-menu a .menu-icon-img svg g #intelligence path {
    stroke: $white !important;
}

.subLink-2 a:hover .menu-icon-img .st0 {
    // fill: $white !important;
}

.subLink-1 a:hover .menu-icon-img svg path {
    // fill: $white !important;
}

.mainLink a:hover .menu-icon-img svg g,
.mainLink a:hover .menu-icon-img .st0,
.mainLink a:hover .menu-icon-img svg path,
.mainLink a:hover .menu-icon-img .cls-1 {
    // fill: $white !important;
}

.mainLink a:hover .menu-icon-img svg g #intelligence path {
    // stroke: $white;
}

.subLink-2.active-sub-menu2 a:hover .menu-icon-img .st0 {
    // fill: $white !important;
}

.subLink-2.active-sub-menu2 a .menu-icon-img .st0 {
    // fill: $white !important;
}

.guideSection .guideIcon .st0 {
    fill: $fontgrey !important;
}

.apexcharts-toolbar,
.apexcharts-xaxistooltip-text,
.apexcharts-tooltip-title {
    display: none !important;
}

#combinedWorkflowBarChart {
    .apexcharts-series {
        path {
            cursor: pointer;
        }
    }
}

#scatterChart {
    .apexcharts-legend-marker {
        top: 2px !important;
    }

    .apexcharts-legend {
        padding: 0px;
    }

    .apexcharts-toolbar,
    .apexcharts-xaxistooltip-text,
    .apexcharts-tooltip-title {
        display: none !important;
    }

    .apexcharts-xaxistooltip {
        display: none !important;
    }

    .apexcharts-inner line.apexcharts-xcrosshairs {
        stroke-width: 0;
    }

    .apexcharts-tooltip.apexcharts-theme-light {
        border: 1px solid $grey-active;
    }

    .apex-tooltip b,
    .apex-tooltip strong {
        font-size: 14px;
    }
}

@media (min-width: 3840px) {
    .navbar .navbar-nav li>a {
        font-size: 16px;
    }

    .navbar .navbar-nav>li,
    .navbar .navbar-nav li>a {
        min-height: 60px;
    }

    .subLink-1 a,
    .subLink-2 a {
        font-size: 14px !important;
    }
}

// ::-webkit-scrollbar,
// .left-panel::-webkit-scrollbar {
//     background: transparent !important;
//     width: 0px !important;
// }
// ::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px #fafafa !important;
//     border: 1px solid #E8E8E7;
//     padding-left: 2px;
//     padding-right: 2px;
// }

// ::-webkit-scrollbar-thumb {
//     -webkit-box-shadow: inset 0px 0px 17px 3px #c1c1c1 !important;
//     -moz-box-shadow: inset 0px 0px 17px 3px #c1c1c1 !important;
//     box-shadow: inset 0px 0px 17px 3px #c1c1c1 !important;
//     border-radius: 4px;
//     // background: transparent!important;
// }

// ::-webkit-scrollbar,
// .left-panel:hover::-webkit-scrollbar {
//     background: #fafafa !important;
//     width: 10px !important;
//     height: 10px !important;
//     border: 1px solid #E8E8E7
// }

.commonSummaryTable {
    .loader-img {
        height: 178px !important;
    }
}

.navbar-nav {
    list-style: none !important;
}

.header-menu .open>.dropdown-menu {
    background-color: transparent !important;
    border-radius: 6px !important;
    box-shadow: none;
    border: none;
    left: auto;
    right: 125px;
}

.header-menu .btn-default:active:hover,
.header-menu .btn-default.active:hover,
.header-menu .open>.dropdown-toggle.btn-default:hover,
.header-menu .btn-default:active:focus,
.header-menu .btn-default.active:focus,
.header-menu .open>.dropdown-toggle.btn-default:focus,
.header-menu .btn-default:active.focus,
.header-menu .btn-default.active.focus,
.header-menu .open>.dropdown-toggle.btn-default.focus {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;

    .dropdown-toggle {
        background-color: $white !important;
        box-shadow: none !important;
    }

    .caret {
        display: none !important;
    }
}

.header-menu .dropdown-toggle,
.header-menu .dropdown-toggle:active,
.header-menu .dropdown-toggle:focus {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;

    .dropdown-toggle {
        background-color: $white;
        box-shadow: none;
    }

    .caret {
        display: none;
    }
}

.app-spinner {
    /* position: fixed; */
    top: 0;
    width: 100%;
    text-align: center;
    height: 100%;
    z-index: 20;
    /* height: 100%; */
    display: flex;

    // .spinner-img {
    //   width: 150px;
    //   height: 150px;
    //   /* position: absolute; */
    //   /* left: 50%; */
    //   /* top: 50%; */
    //   /* transform: translate(0%, -50%); */
    //   margin: auto;
    // }
}

.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper {
    display: none;
}

.reactTooltipStyle {
    color: $black !important;
    background-color: $lightgray !important;
    border: 1px solid $section-tooltip-border !important;
    padding: 10px 20px !important;
    font-size: 14px !important;
    max-width: 350px !important;
    white-space: normal !important;
    opacity: 1 !important;
    // line-height: 1;
    word-break: normal !important;
    word-wrap: break-word;
    word-spacing: normal;
}

.tooltip.in {
    opacity: 1 !important;
}

.tooltip.left .tooltip-arrow {
    border-left-color: $section-tooltip-border !important;
}

.tooltip.right .tooltip-arrow {
    border-right-color: $section-tooltip-border !important;
}

.tooltip.top .tooltip-arrow {
    border-top-color: $section-tooltip-border !important;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: $section-tooltip-border !important;
}

.place-bottom::after {
    border-bottom-color: $section-tooltip-border !important;
}

.place-top::after {
    border-top-color: $section-tooltip-border !important;
}

.place-right::after {
    border-right-color: $section-tooltip-border !important;
}

.place-left::after {
    border-left-color: $section-tooltip-border !important;
}

.css-yk16xz-control,
.css-yk16xz-control:hover {}

.css-1rhbuit-multiValue {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 16px !important;
}

.css-sv84l7-placeholder {
    margin-left: 7px !important;
}

.css-b8ldur-Input {
    width: 90%;
    height: 35px;
    font-size: 14px;

    div {
        width: 90%;
        height: 35px;
        font-size: 14px;
    }

    input {
        height: 35px;
        font-size: 14px !important;
        padding: 0px 5px !important;
        border: none !important;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: red;
        opacity: 1;
        /* Firefox */
    }
}

.switchOrg_btn {
    padding: 4px 7px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: transparent;
    background-size: cover;
    font-family: "Lato", sans-serif;
    font-size: 15px;
    color: $grey-active;
    -webkit-text-decoration: none solid $grey-active;
    text-decoration: none solid $grey-active;
    text-align: center;
    // position: absolute;
    top: -2px;
    /* right: -29px; */
    // margin-left: 10px;
    cursor: pointer;

    img {
        height: 30px;
        filter: brightness(1);
    }

    &:disabled img {
        filter: brightness(0.9);
    }
}

#SessionModalDiv {
    background-color: rgba(0, 0, 0, 0.8);
    height: 100vh;
    // position: absolute;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    // display: flex;
    z-index: 99999;

    .modal {
        max-width: 600px;
        margin: 0 auto;
        left: 45%;
        height: 200px;
        background-color: white;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 30%;
        border-radius: 6px;

        .modal-body {
            padding: 20px 35px;
        }
    }
}

.Collapsible__contentOuter {
    padding: 0px 15px;
}

// .abc:nth-child(3):hover ~ .abc > .colorPicker {
//     background-color: red;
// }
.nv-pieWrap .nv-pie.nv-chart-chart {
    transform: translate(0, 10px) !important;
}

.guidesDonut .nv-pieWrap .nv-pie.nv-chart-chart {
    transform: translate(0, 15px) !important;
}

.assima_icon {
    margin-left: 15px;
}

.logo-img-div {
    width: 280px;
    position: relative;
    left: -5px;
    height: 74px;
    // border-right: 1px solid $section-tooltip-bg;
    top: -9px;
}

.prod_icon {
    margin-left: 15px;
}

.preview_icon {
    margin-left: 15px;
}

.zIndex9 {
    z-index: 9;
}

.zIndex1 {
    z-index: 1;
}

#dropdown-custom-header {
    width: 180px;
    // margin-right: 85px;
    margin-top: 3px;

    .dropdown-toggle {
        height: 63px !important;
        margin-top: -4px !important;
        border: none !important;
    }

    .dropdown-menu.show {
        top: 50px !important;
        padding: 0px !important;
        border: none !important;
        right: 112px !important;
    }
}

#dropdown-custom-header {
    #language-filter-dropdown {
        .dropdown-menu.show {
            top: 215px !important;
            padding: 10px !important;
            border: none !important;
            left: auto !important;
            right: 6px !important;
            position: fixed !important;
        }
    }
}

.nav-link {
    color: $grey6 !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: 400;
    border-bottom: 3px solid transparent !important;
    /* Ensure color is specified */
    border-left: none !important;
    border-right: none !important;
    /* Add space before 'important' */
    border-top: none !important;
    /* Specify 'none' correctly */

    &:hover {
        border-bottom: 3px solid #005bf0 !important;
        /* Ensure color is specified */
        border-left: none !important;
        border-right: none !important;
        /* Add space before 'important' */
        border-top: none !important;
        /* Specify 'none' correctly */
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 700;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
        border-bottom: 3px solid #005bf0 !important;
        /* Ensure color is specified */
        border-left: none !important;
        border-right: none !important;
        /* Add space before 'important' */
        border-top: none !important;
        /* Specify 'none' correctly */
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700;
    }
}



.nav-link.active {

    font-weight: 700;
    text-transform: uppercase;

    border-bottom: 3px solid #005bf0 !important;
    /* Ensure color is specified */
    border-left: none !important;
    border-right: none !important;
    /* Add space before 'important' */
    border-top: none !important;
    /* Specify 'none' correctly */


    &:focus {
        border-bottom: 3px solid #005bf0 !important;
        /* Ensure color is specified */
        border-left: none !important;
        border-right: none !important;
        /* Add space before 'important' */
        border-top: none !important;
        /* Specify 'none' correctly */
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700;
    }

    &:hover {
        border-bottom: 3px solid #005bf0 !important;
        /* Ensure color is specified */
        border-left: none !important;
        border-right: none !important;
        /* Add space before 'important' */
        border-top: none !important;
        /* Specify 'none' correctly */
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 700;
    }
}

.passChip {
    padding: 2px 10px;
    width: 100px;
    margin-left: 30px;
    text-align: center;
    color: white;
    font-weight: 600;
    background-color: $success;
    border-radius: 4px;
    font-size: 12px !important;
}

.failChip {
    padding: 2px 10px;
    width: 100px;
    margin-left: 30px;
    text-align: center;
    color: white;
    font-weight: 600;
    background-color: $deleted;
    border-radius: 4px;
    font-size: 12px !important;
}

.incompleteChip {
    padding: 2px 10px;
    width: 100px;
    margin-left: 30px;
    text-align: center;
    color: white;
    font-weight: 600;
    background-color: $orangesandybrown;
    border-radius: 4px;
    font-size: 12px !important;
}

.barChartWithFocus {
    cursor: pointer;

    .nv-legend {
        display: none;
    }
}

.exportExcelModal {
    width: 1000px !important;
    max-width: 1000px !important;
}

// .btn-primary {
//     color: #fff;
//     background-color: inherit !important;
//     border-color:inherit !important;
//     border-top-color:inherit !important;
//     border-right-color:inherit !important;
//     border-bottom-color:inherit !important;
//     border-left-color:inherit !important;
// }

// .right-panel, header{
//     display: none;
// }

.active-sub-menu>.dashboardDefault-icon>path,
.mainLink .dashboardDefault-icon path,
.dashboardDefault-icon path {
    fill: $primaryColor-active !important;
}

.addDashboard-btn {
    &:hover .pinIcon path {
        fill: $white !important;
    }
}

.dashboardIcon-1 {
    background-color: #6c19ff;
}

.dashboardIcon-2 {
    background-color: #fa91ad;
}

.dashboardIcon-3 {
    background-color: #f5bc00;
}

.dashboardIcon-4 {
    background-color: #f7e677;
}

.dashboardIcon-5 {
    background-color: #3dd9eb;
}

.dashboardIcon-6 {
    background-color: #8890c2;
}

.dashboardIcon-7 {
    background-color: #c8fa86;
}

.dashboardIcon-8 {
    background-color: #8e8fa6;
}

.dashboardIcon-9 {
    background-color: #ffb515;
}

.dashboardIcon-10 {
    background-color: #af84db;
}

.dashboardIcon-11 {
    background-color: #9cf3f1;
}

.dashboardIcon-12 {
    background-color: #f7e677;
}

.dashboardIcon-13 {
    background-color: #3dd9eb;
}

.dashboardIcon-14 {
    background-color: #fcb092;
}

.dashboardIcon-15 {
    background-color: #9977f7;
}

// .form-control,
// input,
// textarea,
// .react-datepicker-wrapper,
// .react-datepicker__input-container,
// .react-datepicker-ignore-onclickoutside {
//     &:disabled {
//         color: grey;
//     }

//     &:focus {
//         + {
//             .placeholderText {
//                 opacity: 1;
//                 transform: scale(0.75) translateY(-100%) translateX(-30px);
//                 background-color: $white;
//                 left: 20px;
//                 position: absolute;
//                 padding: 0px;
//                 color: $primaryColor-enable;
//                 top: 0px;
//                 opacity: 1;
//                 transform: scale(0.75) translateY(-100%) translateX(-30px);
//                 background-color: $white;
//                 left: 20px;
//                 position: absolute;
//                 padding: 0px;
//                 color: $primaryColor-enable;
//                 top: 0px;
//             }
//         }

//         border: $primaryColor-enable 2px solid;
//         border-color: $primaryColor-enable !important;
//     }

// &:focus-visible {
//     + {
//         .placeholderText {
//             opacity: 1;
//             transform: scale(0.75) translateY(-100%) translateX(-30px);
//             background-color: $white;
//             left: 20px;
//             position: absolute;
//             padding: 0px;
//             color: $primaryColor-enable;
//             top: 0px;
//             opacity: 1;
//             transform: scale(0.75) translateY(-100%) translateX(-30px);
//             background-color: $white;
//             left: 20px;
//             position: absolute;
//             padding: 0px;
//             color: $primaryColor-enable;
//             top: 0px;
//         }
//     }
//     outline: none;
//     border: $primaryColor-enable 1px solid;
//     border-color: $primaryColor-enable !important;
// }

//     &:not(:placeholder-shown) {
//         + {
//             .placeholderText {
//                 opacity: 1;
//                 transform: scale(0.75) translateY(-100%) translateX(-30px);
//                 background-color: $white;
//                 left: 20px;
//                 position: absolute;
//                 padding: 0px;
//                 color: $primaryColor-enable;
//                 top: 0px;
//             }
//         }
//     }

//     &:not(:-ms-input-placeholder) {
//         + {
//             .placeholderText {
//                 opacity: 1;
//                 transform: scale(0.75) translateY(-100%) translateX(-30px);
//                 background-color: $white;
//                 left: 20px;
//                 position: absolute;
//                 padding: 0px;
//                 color: $primaryColor-enable;
//                 top: 0px;
//             }
//         }
//     }

//     box-shadow: none !important;
//     // border: #eaeaea 2px solid;
// }

.css-yk16xz-control:focus {
    border-color: #38b6a0 !important;
}

.css-1pahdxg-control:hover {
    border-color: #38b6a0 !important;
    box-shadow: none !important;
}

.apexcharts-data-labels .apexcharts-datalabel {
    filter: none !important;
}

.filtersDiv {
    // height: 65px;
    // position: fixed;
    // z-index: 1;
    // width: calc(100% - 245px);
}

.navbar .navbar-nav>.level1>a:hover {
    // background-color: #26273b!important;
}

.left-panel ul.panel-collapse li.level2 a:hover {
    // background-color: #1e1f2f!important;
}

.left-panel ul.panel-collapse li.level3 a:hover {
    // background-color: #0f0f17!important;
}

// .table-fixed-column-outter {
//     position: relative;
//     border: 1px solid #ebebeb;
//     border-radius: 4px;
//   }
//   .table-fixed-column-inner {
//     overflow-x: scroll;
//     overflow-y: visible;
//     //width: 400px;
//     margin-left: 170px;
//     #appTablesummary.table {
//       margin-bottom: 0.25rem;
//     }
//   }
.bubbleChart svg {
    display: flex;
    margin: 100px auto;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.no-border-right {
    border-right: none !important;
}

.breadcrumbText {
    a {
        color: black;
    }

    .fa-angle-right {
        margin: 0 10px !important;
    }
}

.headerDropDown {
    display: block !important;

    span {
        display: contents;
    }
}

.refreshBtn {
    width: 77px;
    height: 32px;
    // padding: 4px 16px 4px 16px;
    border-radius: 4px;
    background-color: #f5f5f5;
    border: 1px solid #e6e6e6;
    color: #bbbbbb;
    font-size: 13px;
    font-weight: 700;
}

.outer-border {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
}

.justifyCenter {
    justify-content: center;
}


.btn-primary {
    height: 40px;
    background-color: $primaryBlue-color !important;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005bf0;
    box-shadow: 0px 1px 2px 0px #0000000a !important;
    color: white !important;
    border-color: transparent !important;

    &:hover {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
            linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005bf0 !important;
        box-shadow: 0px 1px 2px 0px #0000000a;
    }

    &:focus {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005bf0 !important;
        border: 2px solid #005fcc;
        box-shadow: 0px 1px 2px 0px #0000000a;
    }

    &:active {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005bf0 !important;
        border: 2px solid #005fcc;
        box-shadow: 0px 1px 4px 0px #00000080 inset;
    }
}

.btn-outline-primary {
    color: #005BF0 !important;
    background: #ffffff !important;
    border-color: #005BF0 !important;
    height: 40px !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
    font-weight: 700;
    font-size: 15px;

    &:hover {
        background: rgba(0, 91, 240, 0.08);
        border: 1px solid rgba(0, 91, 240, 1);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
    }

    &:focus {
        border: 2px solid rgba(0, 95, 204, 1);
        color: #005BF0 !important;
        background: #ffffff !important;
        border-color: #005BF0 !important;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);

    }

    &:disabled,
    &.disabled {
        background: #f5f5f5 !important;
        color: #bbbbbb !important;
        border: #e6e6e6 !important;
        opacity: 1!important;
    }
}


.btn-secondary {
    background: linear-gradient(0deg, #F5F5F5, #F5F5F5),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%) !important;
    box-shadow: 0px 1px 2px 0px #00000014 !important;
    height: 40px !important;
    padding: 8px 16px 8px 16px !important;
    gap: 4px;
    border-radius: 4px !important;
    border: 1px solid #BBBBBB !important;
    color: #555555 !important;
    font-size: 15px !important;
    font-weight: 700 !important;


    &:hover {
        background: linear-gradient(0deg, #F5F5F5, #F5F5F5),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%),
            linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%) !important;
        border: 1px solid #BBBBBB !important;
        box-shadow: 0px 1px 2px 0px #00000014 !important;
    }

    &:focus {
        background: linear-gradient(0deg, #F5F5F5, #F5F5F5),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%) !important;
        border: 2px solid #005FCC !important;
        box-shadow: 0px 1px 2px 0px #00000014 !important;
    }

    &:active {
        background: linear-gradient(0deg, #F5F5F5, #F5F5F5),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%) !important;
        border: 1px solid #BBBBBB !important;
        box-shadow: 0px 1px 4px 0px #00000080 inset !important;
    }

    &:disabled,
    &.disabled {
        background: #F5F5F5 !important;
        border: 1px solid #E6E6E6 !important;
        box-shadow: 0px 1px 2px 0px #00000014 !important;
        color: #BBBBBB !important;
        pointer-events: none;
    }
}

.btn-danger {
    color: #fff !important;
    font-size: 15px;
    border: none !important;
    background: linear-gradient(0deg, #DE350B, #DE350B),
        linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%) !important;
    box-shadow: 0px 1px 2px 0px #21222633 !important;

    &:hover {
        background: linear-gradient(0deg, #DE350B, #DE350B),
            linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%),
            linear-gradient(90deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%) !important;
        box-shadow: 0px 1px 2px 0px #21222633;
        border: none !important;
    }

    &:focus {
        border: 2px solid #005FCC !important;
        box-shadow: 0px 1px 2px 0px #21222633 !important;
        background: linear-gradient(0deg, #DE350B, #DE350B),
            linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%) !important;

    }

    &:active {
        background: linear-gradient(0deg, #DE350B, #DE350B),
            linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%) !important;
        box-shadow: 0px 1px 4px 0px #00000080 inset;
    }

    &:disabled,
    &.disabled {
        box-shadow: 0px 1px 2px 0px #0000000A !important;
        background: #F5F5F5 !important;
        border: 1px solid #E6E6E6 !important;
    }
}

.displayFlex,
.displayFlexColumn {
    display: flex;

    &.align-items-end {
        align-items: end;
    }

    &.align-items-start {
        align-items: start;
    }

    &.align-items-center {
        align-items: center;
    }

    &.justify-space-between {
        justify-content: space-between !important;
    }

    &.justify-content-center {
        justify-content: center;
    }

    &.justify-content-start {
        justify-content: start;
    }

    &.justify-content-end {
        justify-content: end;
    }

    &.justify-content-space-even {
        justify-content: space-evenly !important;
    }

    &.gap-10 {
        gap: 10px;
    }
}

.displayFlexColumn {
    flex-direction: column !important;
}


.exportBtn-div {

    .dropdown-item{
        text-align: left;
    }

    .dropdown-item:hover {
        background-color: transparent;
    }

    .super-colors {
        top: 40px;
        right: -1px;
    }

    .btn-group {
        position: fixed;
        top: 0px;
        z-index: 9991;
        right: 110px;
        top: 16px;
        /* width: 130px; */
        height: 40px;
        font-size: 15px;
        font-weight: 700;
        color: white;

        .btn-info {
            height: 40px;
            width: 122px;
            background-color: $primaryBlue-color !important;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005bf0;
            box-shadow: 0px 1px 2px 0px #0000000a !important;
            color: white;
            border-color: transparent !important;
            border-radius: 4px 0px 0px 4px !important;
            line-height: 0.6;
            &:hover {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
                    linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005bf0 !important;
                box-shadow: 0px 1px 2px 0px #0000000a;
            }

            &:focus {
                background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005bf0 !important;
                border: 2px solid #005fcc;
                box-shadow: 0px 1px 2px 0px #0000000a;
            }

            &:active {
                background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%), #005bf0 !important;
                border: 2px solid #005fcc;
                box-shadow: 0px 1px 4px 0px #00000080 inset;
            }
        }

        .dropdown-toggle-split {
            height: 40px;
            width: 40px;
            background-color: $primaryBlue-color !important;
            /* Fallback color */
            background-image:
                linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%),
                url("./assets/img/whiteDownArrow.svg");
            background-repeat: no-repeat;
            background-position: center;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.0392156863) !important;
            color: white;
            border-color: transparent !important;
            border-left-color: white !important;
            border-radius: 0px 4px 4px 0px !important;

            &:active {
                background-image:
                    linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
                    linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%),
                    url("./assets/img/whiteDownArrow.svg") !important;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
            }

            &:hover {
                background-image:
                    linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%),
                    linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%),
                    url("./assets/img/whiteDownArrow.svg") !important;
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
            }

            &:active {
                background: none;
                border: 2px solid #005fcc;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5) inset;
                background-repeat: no-repeat;
                background-position: center;
                border: 2px solid #005fcc;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5) inset;
            }

            &:focus {
                background: none;
                border: 2px solid #005fcc;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5) inset;
                background-repeat: no-repeat;
                background-position: center;
                border: 2px solid #005fcc;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5) inset;
            }
        }
    }
}

.form-control {
    height: 40px !important;

    &::placeholder {
        color: #707070 !important;
        font-size: 16px;
        font-weight: 400;
    }
}

input.form-control:focus,
textarea.form-control:focus {
    border: 2px solid #005FCC !important;
}

.input-radio-custom {
    width: 24px !important;
    height: 24px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #383838;
    border-radius: 50%;
    cursor: pointer;
    outline: 0;
    padding: 4px !important;
    background-clip: content-box;
}

.input-radio-custom:hover {
    border: 1px solid #BBBBBB;
    background-color: #005BF0;
    opacity: 0.5;
}

.input-radio-custom:checked {
    border: 2px solid #005BF0;
    background-color: #005BF0;
}

.input-radio-custom:focus {
    border: 1px solid #005BF0;
}

.super-colors.dropdown-menu {
    top: 40px !important;
    right: 0px !important;
}

#dropdown-custom-header {
    .btn-primary {
        background-image: none !important;
        text-shadow: none !important;
        background: none !important;
        margin-top: -35px;
        float: right;

        &:hover {
            background-image: none !important;
            text-shadow: none !important;
            background: none !important;
            margin-top: -35px;
            float: right;
        }

        &:focus {
            background-image: none !important;
            text-shadow: none !important;
            background: none !important;
            margin-top: -35px;
            float: right;

        }
    }
}


.dashboardIcon-div {
    cursor: pointer;
}

.btn-group {
    .btn-primary {
        &:hover {
            background: content-box !important;
        }
    }
}

.sortIcons-span {
    float: right;
}

.btn-primary.dropdown-toggle {
    font-weight: 400 !important;

    &:hover {
        border: 1px solid #BBBBBB !important;
        text-align: left;
        overflow: hidden;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.5019607843), #f5f5f5) !important;
    }

    &:focus {
        border: 1px solid #BBBBBB !important;
        text-align: left;
        overflow: hidden;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.5019607843), #f5f5f5) !important;
    }


    &:active {
        border: 1px solid #BBBBBB !important;
        text-align: left;
        overflow: hidden;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.5019607843), #f5f5f5) !important;
    }

    &:disabled {
        background: #f5f5f5 !important;
        color: #bbbbbb !important;
        opacity: 1!important;

        .date-input {
            color: #bbbbbb !important;
            border: #e6e6e6 !important;
            opacity: 1!important;

        }
    }
}

.language-para {
    height: 30px;
    margin-bottom: 0px;
}

.donutDiv {
    .nv-chart {
        margin: 0 auto!important;
    }
}

.recharts-pie-sector {
    outline: none;
}