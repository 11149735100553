#tooltipsDetailSection {
    .userActivityBtn{
        right: 35px;
        top: 55px;
    }

    .warningDiv {
        position: absolute;
        top: 50%;
        /* right: 50%; */
        transform: translateX(-50%);
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px 20px 0px 20px;
        background-color: #feeaa5;
        color: #9a7600;
        border-radius: 4px;
        font-size: 14px;

        .closeWarning {
            border: none;
            background-color: transparent;
            width: 20px;
            height: 20px
        }

        p {
            margin-bottom: 10px;
        }
    }
}