@import "style_variables";

.cardDimensions {
    min-width: 100%;
    max-width: 100%;
    width: 100%;

    min-height: 200px !important;
    max-height: 200px !important;
    height: 200px !important;

    padding: 20px 0px 20px 0px;
    display: inline-block;
    margin-bottom: 35px;
    margin-top: -20px;
}

@mixin cardPadding-LR($LPadding, $RPadding) {
    padding-left: $LPadding;
    padding-right: $RPadding;
}

.arrowWeight {
    font-weight: 900 !important;
    font-size: 14px !important;
}

.fa-refresh {
    font-size: 13px !important;
}

.requestLimitMsg {
    width: 100% !important;
    position: absolute;
    top: 60px;
    left: 10px;
}

.width-fit {
    width: fit-content !important;
}

.noData-div {
    font-size: 13px;
    font-weight: 700;
}

.exportCards-div {
    overflow-y: auto;
    height: calc(100vh - 180px);
}

.templateBG {
    cursor: pointer;
}

.templateBG:hover {
    background-color: #f1fcfa !important;
}

.templateTitleInput {
    margin: 0 0 14px 4px;
    width: calc(100% - 12px);
}

.borderRadius50P {
    border-radius: 50%;
}

.marginBottm-20 {
    margin-bottom: 20px !important;
}

.displayFlexColumn {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start !important;
}

.daySelectionDiv {
    margin-left: 0px;

    .daySelectDropdown {
        margin-left: -16px !important;

        .source-menu-day-of-week {
            top: 40px !important;
            left: 15px !important;
            width: 180px !important;
        }
    }
}

.timeSelectionDiv,
.daySelectionDiv {
    margin-bottom: 50px;
}

.timeSelectionDiv {
    .react-datepicker__input-container input {
        margin-top: 0px !important;
        width: 182px !important;
    }

    .react-datepicker-popper {
        position: relative !important;
        top: 0px !important;
        transform: none !important;
    }

}

.popperDivOneTime {
    .react-datepicker-popper {
        width: 400px
    }
}

.tooltipText {
    width: 100px;
}

.iconHolder {
    position: absolute !important;
    right: 15px !important;
    display: "flex";
    font-size: 18px;
}

.paddingLeftZero {
    padding-left: 0px !important;
}

.startEndMenuClass {
    padding: 0 !important;
    height: 98%;

    .react-datepicker {
        width: 100% !important;
        height: 100% !important;
        margin: 0px !important;
    }
}

.exportComponent {
    padding: 98px 24px !important;

    .exportDiv {
        display: flex;
        flex-direction: column;
        gap: 16px !important;
        box-sizing: border-box;
        background-color: #ffffff !important;
        padding: 16px !important;
        box-shadow: 0px 2px 2px 0px #2122263D;
        box-shadow: 0px 0px 2px 0px #0000001F;
        border-radius: 2px;


        .header {
            font-family: "Lato";
            font-size: 20px;
            font-weight: 900;
            line-height: 27px;
            text-align: left;
            color: #383838;
        }

        .content-container {
            .custom-table {
                margin-top: 24px;
                border-radius: 4px 0px 0px 0px;
                background: #FFFFFF;
                border: 1px solid #E6E6E6;
                width: 100% !important;
                table-layout: fixed;

                tr {
                    border-bottom: 1px solid #E6E6E6;

                    th,
                    td {
                        white-space: normal;
                        word-wrap: break-word;
                        padding: 12px 16px !important;
                        vertical-align: top;
                        font-size: 14px;

                        img {
                            cursor: pointer;
                        }
                    }

                    th {
                        font-weight: 700;
                        color: #383838;
                    }

                    td {
                        color: #555555;
                        font-weight: 400;
                    }

                    .chips {
                        display: inline-block;
                        color: #555555;
                        font-size: 13px;
                        font-weight: 700;
                        padding: 4px 8px;
                        background: #F5F5F5;
                        border-radius: 12px;
                        margin-right: 5px;
                        margin-top: 4px !important;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                &.jobTable {

                    th:nth-child(1),
                    td:nth-child(1),
                    th:nth-child(2),
                    td:nth-child(2),
                    th:nth-child(6),
                    td:nth-child(6),
                    th:last-child,
                    td:last-child {
                        width: 140px;
                    }

                    th:nth-child(3),
                    td:nth-child(3),
                    th:nth-child(4),
                    td:nth-child(4) {
                        width: 145px;
                    }
                }

                &.templateTable {

                    th:nth-child(1),
                    td:nth-child(1),
                    th:nth-child(2),
                    td:nth-child(2),
                    th:nth-child(3),
                    td:nth-child(3),
                    th:nth-child(5),
                    td:nth-child(5) {
                        width: 165px;
                    }
                }
            }
        }
    }
}

.datepicker-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    .react-datepicker-wrapper {
        width: 100% !important;

        .custom-datepicker,
        input {
            width: 100% !important;
            height: 40px;
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: space-between;
            padding: 8px 10px !important;
            background-color: #ffffff !important;
            border: 1px solid #707070 !important;
            border-radius: 4px !important;
            box-shadow: 0px 1px 2px 1px #55555533 inset;
            cursor: pointer;

            &:hover {
                border: 1px solid #707070 !important;
            }

            &:focus {
                border: 1px solid #707070 !important;
            }

            &:active {
                border: 1px solid #707070 !important;
            }
        }
    }

    img {
        position: absolute;
        right: 10px;
        pointer-events: none;
    }


    .dateRange-title {
        display: none !important;
    }
}

.displayFlexColumn {
    .dropdown {
        width: 100% !important;

        .export-dropdown {
            width: 100% !important;
            height: 40px;
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: space-between;
            padding: 8px 10px !important;
            background-color: #ffffff !important;
            border: 1px solid #707070 !important;
            border-radius: 4px;
            box-shadow: 0px 1px 2px 1px #55555533 inset;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .custom-control {
                display: flex!important;
            }

            .dropdown-label {
                font-size: 16px;
                font-weight: 400;
                color: #000000;

                .chips {
                    display: inline-block;
                    color: #555555;
                    font-size: 13px;
                    font-weight: 700;
                    padding: 4px 8px;
                    min-width: 35px !important;
                    background: #F5F5F5;
                    border-radius: 12px;
                    margin-right: 5px;
                    margin-top: 4px !important;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                }
            }

            .dropdown-count {
                position: absolute;
                top: 0px;
                left: 0px;
                background: #005BF0;
                font-size: 12px;
                color: white;
                font-weight: 600;
                padding-top: 6px;
                text-align: center !important;
                border-radius: 50%;
            }
        }

        .dropdown-menu {
            width: 100% !important;
            padding: 0 !important;
            top: 40px !important;
            cursor: pointer;
            border: 2px solid transparent;
            height: fit-content;

            &.show {
                display: block !important;
            }

            .custom-control {
                cursor: pointer;
                border: 2px solid transparent;
                display: flex!important;

                

                label {
                    cursor: pointer;
                }

                &:hover {
                    border: 2px solid #005FCC;
                    border-radius: 4px;
                }

                &.active {
                    border: 2px solid #005FCC;
                    border-radius: 4px;
                }
            }

            .applyButton {
                padding-top: 10px !important;
                padding-bottom: 10px !important;
            }
        }

        .export-datepicker-dropdown {
            width: auto !important;
        }
    }
}


.custom-modal {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;

    .modal-dialog {
        min-width: 500px !important;
        max-width: 720px !important;
        // overflow-y: auto;
        height: 100%;

        .modal-content {
            .modal-header {
                padding: 18px 24px;
                height: 60px !important;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background-color: #ffffff !important;
                border: none !important;

                .modal-title {
                    font-size: 16px;
                    font-weight: 700;
                }

                .close-icon {
                    cursor: pointer;
                }
            }

            .modal-body {
                position: relative;
                padding: 16px 24px !important;
                display: flex;
                flex-direction: column;
                gap: 16px;

                .custom-label {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 21px;
                    color: #707070 !important;
                }

                .note {
                    font-size: 12px;
                    color: red;
                }

                .input-field {
                    width: 100% !important;
                    height: 40px !important;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 8px 10px !important;
                    background-color: #ffffff !important;
                    border: 1px solid #707070 !important;
                    border-radius: 4px;
                    box-shadow: 0px 1px 2px 1px #55555533 inset;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: text;

                    &::placeholder {
                        color: #707070 !important;
                        font-size: 13px !important;
                        font-weight: 700 !important;
                    }
                }
            }

            .modal-footer {
                border: none !important;
            }
        }
    }
}

.delete-modal {
    .modal-dialog {
        width: 500px !important;
    }
}

.create-btn,
.save-btn {
    font-size: 15px !important;
    font-weight: 700 !important;
}

// .dateRange-title {
//     visibility: hidden;
// }

.caret-icon {
    position: absolute;
    right: 10px;
    pointer-events: none;
    z-index: 40;
}


.datepicker-container {
    .datepiecker-div {
        justify-content: start !important;
        width: 100% !important;

        .dropdown {
            width: 100% !important;

            &.show {
                border: 2px solid #005FCC;
                border-radius: 4px;
            }

            button.dropdown-toggle {
                width: 100% !important;
                background-color: #ffffff !important;
                border: 1px solid #707070 !important;
                border-radius: 4px !important;
                box-shadow: 0px 1px 2px 1px #55555533 inset;

                &:hover {
                    border: 2px solid #005FCC;
                    border-radius: 4px;
                }

                &.show {
                    border: 2px solid #005FCC;
                    border-radius: 4px;
                }

                .date-input {
                    border: none !important;
                    width: 100% !important;
                }
            }

            i.dropdown-toggle {
                visibility: hidden;
            }
        }
    }
}

.linkText {
    color: #005BF0 !important;
    font-weight: 700;
    font-size: "13px";
    cursor: pointer;
}